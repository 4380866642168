import React from 'react';
import { Brain, Shield, TrendingUp, FileSearch } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white p-6 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-105">
    <div className="flex items-center justify-center h-16 w-16 rounded-full bg-blue-100 mx-auto mb-4">
      <Icon className="h-8 w-8 text-blue-600" />
    </div>
    <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const AIGovernanceSection = () => (
  <section className="py-12 bg-gradient-to-r from-purple-100 to-blue-100 rounded-2xl my-16">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-4xl font-bold text-center mb-8 text-gradient leading-relaxed">
        Comprehensive AI Governance
      </h2>
      <p className="text-xl text-center mb-12 text-gray-700">
        Ensure ethical, transparent, and compliant AI operations with our advanced governance features:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <FeatureCard
          icon={Brain}
          title="Model Explainability"
          description="Ensure transparency in AI decision-making processes for regulatory compliance and stakeholder trust."
        />
        <FeatureCard
          icon={Shield}
          title="Bias Detection"
          description="Identify and mitigate bias in AI models to ensure fair and ethical use in healthcare applications."
        />
        <FeatureCard
          icon={TrendingUp}
          title="Performance Monitoring"
          description="Continuously monitor AI model performance to maintain accuracy and regulatory compliance."
        />
        <FeatureCard
          icon={FileSearch}
          title="Audit Trail"
          description="Maintain comprehensive logs of AI model development, testing, and deployment for regulatory inspections."
        />
      </div>
    </div>
  </section>
);

export default AIGovernanceSection;