import React, { useState, useEffect } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { Users, Shield, Activity, Cog, BarChart2, TrendingUp, Lock, AlertTriangle, LifeBuoy, Settings, ExternalLink, Clock, Brain, Flag, Heart, Database, AlertOctagon, Leaf } from 'lucide-react';
import ScoreCard from './ScoreCard';
import AIRiskAssessment from './AIRiskAssessment';
import EthicalAICompliance from './EthicalAICompliance';
import AIObservabilitySuite from './AIObservabilitySuite';
import AIModelList from './AIModelList';
import AIIncidentManagement from './AIIncidentManagement';
import LokCOIntegration from './LokCOIntegration';
import { Button } from './ui/Button';
import EUAIActComplianceTracker from './EUAIActComplianceTracker';
import NISTAIFrameworkOverview from './NISTAIFrameworkOverview';
import EUAIActComplianceHub from './EUAIActComplianceHub';
import NISTAIFrameworkHub from './NISTAIFrameworkHub';
import EnhancedAIOverview from './EnhancedAIOverview';

const appVersion = process.env.REACT_APP_VERSION || '0.3';

const formatNavItemName = (name) => {
  return name
    .split(/(?=[A-Z])/)
    .map(word => word === 'Ai' ? 'AI' : word)
    .join(' ');
};

const fetchDashboardData = async () => {
  // Simulating API call
  await new Promise(resolve => setTimeout(resolve, 1000));
  return {
    overallAIScore: 82,
    ethicalAIScore: 88,
    aiObservabilityScore: 75,
    aiModels: [
      { id: 1, name: 'Clinical Decision Support AI', lastUpdated: '2023-07-15', status: 'Production' },
      { id: 2, name: 'Drug Discovery ML Model', lastUpdated: '2023-06-22', status: 'Testing' },
      { id: 3, name: 'Patient Risk Stratification AI', lastUpdated: '2023-08-01', status: 'Development' },
    ],
    aiIncidents: [
      { id: 1, description: 'False positive in diagnostic AI', date: '2023-07-10', status: 'Resolved' },
      { id: 2, description: 'Data drift detected in drug discovery model', date: '2023-07-28', status: 'In Progress' },
    ],
    complianceTrend: [
      { month: 'Jan', score: 70 },
      { month: 'Feb', score: 75 },
      { month: 'Mar', score: 78 },
      { month: 'Apr', score: 80 },
      { month: 'May', score: 82 },
      { month: 'Jun', score: 85 },
    ],
    aiInsights: {
      summary: "Compliance score improved by 5% this month. Key areas of improvement: data privacy and third-party risk management.",
      riskAreas: [
        "Vendor assessment process needs updating",
        "Employee training on new regulations required"
      ],
      recommendations: [
        "Implement automated vendor risk scoring",
        "Schedule quarterly compliance training sessions"
      ]
    },
    knowledgeBase: [
      {
        region: "Global",
        standards: [
          { name: "ISO/IEC 42001:2023", link: "https://www.iso.org/standard/81230.html" },
          { name: "IEEE 7000-2021", link: "https://standards.ieee.org/standard/7000-2021.html" },
        ]
      },
      {
        region: "United States",
        standards: [
          { name: "FDA AI/ML Guidelines", link: "https://www.fda.gov/medical-devices/software-medical-device-samd/artificial-intelligence-and-machine-learning-software-medical-device" },
          { name: "NIST AI Risk Management Framework", link: "https://www.nist.gov/itl/ai-risk-management-framework" },
        ]
      },
      {
        region: "European Union",
        standards: [
          { name: "EU AI Act", link: "https://digital-strategy.ec.europa.eu/en/policies/regulatory-framework-ai" },
          { name: "EU MDR", link: "https://ec.europa.eu/health/medical-devices-sector/new-regulations/guidance-mdcg-endorsed-documents-and-other-guidance_en" },
        ]
      },
      {
        region: "Internal",
        standards: [
          { name: "LOKATIAL AI Governance Framework", link: "#" },
          { name: "Healthcare AI Best Practices", link: "#" },
        ]
      }
    ]
  };
};

const navigationItems = [
  { name: 'Overview', icon: Users, category: 'main', status: 'active' },
  { name: 'EU AI Act Compliance', icon: Flag, category: 'compliance', status: 'active' },
  { name: 'NIST AI Framework', icon: Shield, category: 'compliance', status: 'active' },
  { name: 'AI Risk Assessment', icon: AlertTriangle, category: 'core', status: 'active' },
  { name: 'Ethical AI Compliance', icon: Heart, category: 'core', status: 'active' },
  { name: 'AI Observability', icon: Activity, category: 'core', status: 'active' },
  { name: 'AI Model Management', icon: Database, category: 'core', status: 'active' },
  { name: 'AI Incident Management', icon: AlertOctagon, category: 'core', status: 'active' },
  { name: 'Support', icon: LifeBuoy, category: 'support', status: 'active' },
  { name: 'Settings', icon: Settings, category: 'support', status: 'active' },
];

const toKey = (name) => name.toLowerCase().replace(/\s+/g, '');

const AIMSDashboardWireframe = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState('overview');
  const [showLokCO, setShowLokCO] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_DASHBOARDS !== 'true') {
      return;
    }
    
    const loadData = async () => {
      try {
        const data = await fetchDashboardData();
        setDashboardData(data);
      } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
        // Handle error state
      } finally {
        setLoading(false);
      }
    };
    loadData();

    // Set active section based on the current route
    if (location.pathname === '/nist-ai-framework') {
      setActiveSection('nistaiframework');
    } else if (location.pathname === '/eu-ai-act-compliance') {
      setActiveSection('euaiactcompliance');
    } else if (location.pathname === '/aims-dashboard') {
      setActiveSection('overview');
    }
  }, [location.pathname]);

  if (process.env.REACT_APP_ENABLE_DASHBOARDS !== 'true') {
    return <Navigate to="/" />;
  }

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }
  const renderContent = () => {
    switch (activeSection) {
      case 'overview':
        return (
          <EnhancedAIOverview 
            dashboardData={dashboardData}
            EUAIActComplianceTracker={EUAIActComplianceTracker}
            NISTAIFrameworkOverview={NISTAIFrameworkOverview}
            ScoreCard={ScoreCard}
            AIModelList={AIModelList}
            AIIncidentManagement={AIIncidentManagement}
          />
        );
      case 'euaiactcompliance':
        return <EUAIActComplianceHub />;
      case 'nistaiframework':
        return <NISTAIFrameworkHub />;
      case 'airiskassessment':
        return <AIRiskAssessment />;
      case 'ethicalaicompliance':
        return <EthicalAICompliance />;
      case 'aiobservability':
        return <AIObservabilitySuite />;
      case 'aimodelmanagement':
        return <AIModelList models={dashboardData.aiModels} />;
      case 'aiincidentmanagement':
        return <AIIncidentManagement incidents={dashboardData.aiIncidents} />;
      case 'support':
        return (
          <div className="p-4 bg-white rounded shadow">
            <h2 className="text-xl font-semibold mb-4">Support</h2>
            <p>Need help? Contact our support team or check out our documentation.</p>
          </div>
        );
      case 'settings':
        return (
          <div className="p-4 bg-white rounded shadow">
            <h2 className="text-xl font-semibold mb-4">Settings</h2>
            <p>Manage your AIMS settings and preferences here.</p>
          </div>
        );
      default:
        return <div>Select a section</div>;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left Sidebar */}
      <div className="w-64 bg-white shadow-md">
        <div className="p-4">
          <Link to="/" className="text-xl font-bold hover:text-blue-600 transition duration-300">
            LOKATIAL AIMS
          </Link>
        </div>
        
        <Button onClick={() => setShowLokCO(!showLokCO)} className="flex items-center m-4">
          <Brain className="mr-2" size={16} />
          {showLokCO ? 'Hide LokCO' : 'Ask LokCO'}
        </Button>
        
        <nav className="mt-4">
          {['main', 'core', 'compliance', 'sustainability', 'support'].map((category) => (
            <div key={category}>
              {category !== 'main' && <hr className="my-2 border-gray-200" />}
              {navigationItems
                .filter((item) => item.category === category)
                .map(({ name, icon: Icon, status }) => (
                  <button
                    key={toKey(name)}
                    className={`w-full text-left flex items-center px-4 py-2 text-gray-700 ${
                      activeSection === toKey(name) ? 'bg-gray-200' : 'hover:bg-gray-100'
                    } ${status === 'in_development' ? 'text-gray-400' : ''}`}
                    onClick={() => {
                      if (status === 'active') {
                        setActiveSection(toKey(name));
                      }
                    }}
                    disabled={status === 'in_development'}
                  >
                    <Icon className="mr-3 flex-shrink-0" size={20} />
                    <span className="flex-grow truncate">{name}</span>
                    {status === 'in_development' && (
                      <span className="flex items-center text-xs text-gray-400 ml-2">
                        <Clock size={12} className="mr-1" />
                        In Dev
                      </span>
                    )}
                  </button>
                ))}
            </div>
          ))}
        </nav>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 overflow-hidden">
        <header className="bg-white shadow-sm">
          <div className="flex items-center justify-between px-4 py-3">
            <h1 className="text-xl font-semibold">AI Management System Dashboard</h1>
            <div className="flex items-center">
              <Link to="/cms-dashboard" className="mr-4 text-blue-500 hover:text-blue-600 flex items-center">
                <ExternalLink size={16} className="mr-1" />
                CMS Dashboard
              </Link>
              <span className="mr-4 text-sm text-gray-500">v{appVersion}</span>
              <Users className="mr-2" size={20} />
              <span>Tarun S.</span>
            </div>
          </div>
        </header>
        
               
        <main className="p-6 overflow-auto" style={{ height: 'calc(100vh - 64px)' }}>
          {renderContent()}
        </main>
      </div>

      {/* Render LokCO outside the main content area */}
      {showLokCO && (
        <LokCOIntegration
          onClose={() => setShowLokCO(false)}
          dashboardState={{
            aiPerformanceScore: dashboardData.overallAIScore,
            euAiActComplianceScore: 85, // Placeholder value, replace with actual data
            ethicalAiScore: dashboardData.ethicalAIScore,
            aiIncidents: dashboardData.aiIncidents,
            complianceGaps: ['Data governance', 'Model documentation'], // Placeholder array, replace with actual data
          }}
        />
      )}
    </div>
  );
};

export default AIMSDashboardWireframe;