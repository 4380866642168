import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box, VStack, HStack, Heading, Text, Button, Textarea, Select, Input, 
  Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  useColorModeValue, Stack, useToast, FormControl, FormLabel, Checkbox, useTheme, CheckboxGroup, Tag, TagLabel, TagCloseButton, Tooltip, Alert, AlertIcon
} from "@chakra-ui/react";
import { ArrowRight, ArrowLeft, Check, Save, Brain, Zap, Shield, HelpCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import ProcessSelectionStep from './ProcessSelectionStep';
import RiskCategories from './RiskCategories';
import FrameworkTabs from './FrameworkTabs';
import DepartmentDisplay from './DepartmentDisplay';
import useAISuggestions from './useAISuggestions';
import Step1DefineProcess from './Step1DefineProcess';
import ProcessSummaryVisualization from './ui/ProcessSummaryVisualization';

// Simulate AI Call (Assumed this is a placeholder function)
const simulateAICall = async (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        suggestedDepartments: ["Legal", "Compliance", "Quality Assurance", "Regulatory Affairs", "Operations"],
        stakeholders: "Chief Compliance Officer, Head of Regulatory Affairs, Quality Assurance Manager",
        riskAreas: "Regulatory non-compliance, Data integrity issues, GMP violations",
        kpis: ["Compliance training completion rate", "Number of regulatory findings"],
        complianceActivities: ["Regular compliance audits", "Employee compliance training", "Policy and procedure reviews"]
      });
    }, 2000);
  });
};

const ComplianceProcessWizard = ({ onClose, onSave, onDraftSaved }) => {
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [step, setStep] = useState(-1);
  const [maturityLevel, setMaturityLevel] = useState('');
  const [processName, setProcessName] = useState('');
  const [processType, setProcessType] = useState('');
  const [objectives, setObjectives] = useState('');
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);
  const [involvedDepartments, setInvolvedDepartments] = useState([]);
  const [customDepartment, setCustomDepartment] = useState('');
  const [monitoringApproach, setMonitoringApproach] = useState('');
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [relevantStandards, setRelevantStandards] = useState([]);
  const [pharmaRiskCategories, setPharmaRiskCategories] = useState([]);
  const [selectedRiskCategories, setSelectedRiskCategories] = useState([]);
  const [customRiskCategory, setCustomRiskCategory] = useState('');
  const [regulatoryRequirements, setRegulatoryRequirements] = useState([]);
  const [complianceActivities, setComplianceActivities] = useState([]);
  const [monitoringFrequency, setMonitoringFrequency] = useState('');
  const [auditFrequency, setAuditFrequency] = useState('');
  const [correctionPlan, setCorrectionPlan] = useState('');
  const [preventionPlan, setPreventionPlan] = useState('');
  const [loading, setLoading] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const toast = useToast();
  const bgGradient = useColorModeValue("linear(to-br, purple.100, blue.100)", "linear(to-br, purple.900, blue.900)");
  const boxBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("purple.800", "purple.100");
  const steps = ["Select Process", "Define Process", "Process Details & Framework", "Summary & Visualization"];
  const selectBg = useColorModeValue("purple.50", "purple.700");
  const selectBorder = useColorModeValue("purple.200", "purple.500");
  const { aiSuggestions, updateAISuggestions } = useAISuggestions();
  const memoizedAiSuggestions = useMemo(() => aiSuggestions, [aiSuggestions]);
  const [riskPrioritizationData, setRiskPrioritizationData] = useState({});
  
  const addInvolvedDepartment = () => {
    if (customDepartment && !involvedDepartments.includes(customDepartment)) {
      setInvolvedDepartments([...involvedDepartments, customDepartment]);
      setCustomDepartment('');
    }
  };

  const removeInvolvedDepartment = (department) => {
    setInvolvedDepartments(involvedDepartments.filter(dep => dep !== department));
  };

  const addSuggestedDepartment = (dept) => {
    if (!involvedDepartments.includes(dept)) {
      setInvolvedDepartments([...involvedDepartments, dept]);
    }
  };

  const handleRiskPrioritizationChange = (newData) => {
    setRiskPrioritizationData(newData);
  };

  const fetchAISuggestions = useCallback(async () => {
    setIsProcessing(true);
    try {
      await updateAISuggestions({
        processType,
        objectives,
        involvedDepartments,
        selectedRiskCategories,
      });
    } catch (error) {
      console.error("Error fetching AI suggestions:", error);
      toast({
        title: "Error",
        description: "Failed to fetch AI suggestions. Please try again.",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsProcessing(false);
      setShouldFetchSuggestions(false);
    }
  }, [processType, objectives, involvedDepartments, selectedRiskCategories, updateAISuggestions, toast]);

  useEffect(() => {
    if (shouldFetchSuggestions) {
      fetchAISuggestions();
    }
  }, [shouldFetchSuggestions, fetchAISuggestions]);

  const handleObjectivesChange = (e) => {
    setObjectives(e.target.value);
  };

  const handleObjectivesBlur = () => {
    if (objectives.trim().length > 0) {
      setShouldFetchSuggestions(true);
    }
  };

  useEffect(() => {
    if (step === 1 && processType && objectives) {
      fetchAISuggestions();
    }
  }, [step, processType, objectives, fetchAISuggestions]);

  useEffect(() => {
    if (step === 2 && processType) {
      setLoading(true);
      fetchAISuggestions();
      setTimeout(() => {
        if (processType === 'Risk Assessment') {
          setPharmaRiskCategories([
            'Product Quality',
            'Patient Safety',
            'Data Integrity',
          ]);
        } else if (processType === 'Compliance Monitoring') {
          setPharmaRiskCategories([
            'Regulatory Compliance',
            'Supply Chain Integrity',
            'Pharmacovigilance',
          ]);
        }
        setLoading(false);
      }, 1500);
    }
  }, [step, processType, fetchAISuggestions]);
  
  const handleStartDesigning = () => {
    setIsLandingPage(false);
    setStep(0);  // Move to process selection
  };

  const handleNext = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setStep(prevStep => prevStep + 1);
      setIsTransitioning(false);
    }, 1000);
  };

  const handleBack = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      if (step === 0) {
        setIsLandingPage(true);
        setStep(-1);
      } else {
        setStep(prevStep => prevStep - 1);
      }
      setIsTransitioning(false);
    }, 1000);
  };

  const handleFinishAndSave = useCallback(() => {
    const draftProcess = {
      id: Date.now(),
      name: processName,
      type: processType,
      status: 'draft',
      createdAt: new Date().toISOString(),
      objectives,
      involvedDepartments,
      selectedRiskCategories,
      riskPrioritizationData,
      monitoringFrequency,
      auditFrequency,
      complianceActivities,
      correctionPlan,
      preventionPlan,
      selectedCountries,
      relevantStandards
    };

    // Save the draft process
    onSave(draftProcess);

    // Notify parent component to update left navigation
    onDraftSaved(draftProcess);

    // Close the wizard
    onClose();
  }, [
    processName, processType, objectives, involvedDepartments, selectedRiskCategories,
    riskPrioritizationData, monitoringFrequency, auditFrequency, complianceActivities,
    correctionPlan, preventionPlan, selectedCountries, relevantStandards,
    onSave, onDraftSaved, onClose
  ]);

  const handleProcessSelection = (selectedProcessType) => {
    setProcessType(selectedProcessType);
    setStep(1);
  };

  const renderLandingPage = () => (
    <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
      <VStack spacing={8} align="stretch">
        <Heading size="2xl" textAlign="center" bgGradient="linear(to-r, purple.400, blue.500)" bgClip="text">
          Turn compliance ideas into processes instantly with AI
        </Heading>
        <Text fontSize="xl" textAlign="center">
          Visualize, communicate, and iterate on compliance processes in minutes. Empower your compliance team with AI!
        </Text>
        <Box>
          <FormControl>
            <FormLabel fontWeight="bold">Select your organization's compliance maturity level:</FormLabel>
            <Select 
              value={maturityLevel} 
              onChange={(e) => setMaturityLevel(e.target.value)}
              bg={selectBg}
              borderColor={selectBorder}
              _hover={{ borderColor: "purple.300" }}
              _focus={{ borderColor: "purple.400", boxShadow: "0 0 0 1px purple.400" }}
            >
              <option value="beginner">Beginner - Starting our compliance journey</option>
              <option value="intermediate">Intermediate - Established basic compliance processes</option>
              <option value="advanced">Advanced - Mature compliance program in place</option>
            </Select>
          </FormControl>
        </Box>
        <Button 
          colorScheme="purple" 
          size="lg" 
          onClick={handleStartDesigning}
          _hover={{ transform: "translateY(-2px)", boxShadow: "lg" }}
          transition="all 0.2s"
          isDisabled={!maturityLevel}
        >
          Start Designing Your Compliance Process
        </Button>
        <HStack justifyContent="center" spacing={8}>
          <VStack>
            <Brain size={48} color="purple" />
            <Text fontWeight="bold">AI-Powered Insights</Text>
          </VStack>
          <VStack>
            <Zap size={48} color="blue" />
            <Text fontWeight="bold">Rapid Process Creation</Text>
          </VStack>
          <VStack>
            <Shield size={48} color="green" />
            <Text fontWeight="bold">Regulatory Compliance</Text>
          </VStack>
        </HStack>
      </VStack>
    </motion.div>
  );

  const renderStep = () => {
    switch(step) {
      case -1:
        return renderLandingPage();
      case 0:
        return <ProcessSelectionStep onSelectProcess={handleProcessSelection} />;
      case 1:
        return (
          <Step1DefineProcess
            processType={processType}
            processName={processName}
            setProcessName={setProcessName}
            objectives={objectives}
            setObjectives={setObjectives}
            involvedDepartments={involvedDepartments}
            setInvolvedDepartments={setInvolvedDepartments}
            aiSuggestions={aiSuggestions}
            fetchAISuggestions={fetchAISuggestions}
          />
        );
      case 2:
        return (
          <VStack spacing={4} align="stretch">
            <Heading size="md">Step 2: Process Details & Framework</Heading>
            <RiskCategories 
              selectedRiskCategories={selectedRiskCategories}
              setSelectedRiskCategories={setSelectedRiskCategories}
              pharmaRiskCategories={pharmaRiskCategories}
              customRiskCategory={customRiskCategory}
              setCustomRiskCategory={setCustomRiskCategory}
            />
            <FrameworkTabs 
              processType={processType}
              selectedRiskCategories={selectedRiskCategories}
              involvedDepartments={involvedDepartments}
              complianceActivities={complianceActivities}
              setComplianceActivities={setComplianceActivities}
              monitoringFrequency={monitoringFrequency}
              setMonitoringFrequency={setMonitoringFrequency}
              auditFrequency={auditFrequency}
              setAuditFrequency={setAuditFrequency}
              correctionPlan={correctionPlan}
              setCorrectionPlan={setCorrectionPlan}
              preventionPlan={preventionPlan}
              setPreventionPlan={setPreventionPlan}
              aiSuggestions={aiSuggestions}
              riskPrioritizationData={riskPrioritizationData}
              setRiskPrioritizationData={setRiskPrioritizationData}
              onRiskPrioritizationChange={handleRiskPrioritizationChange}
            />
          </VStack>
        );
      case 3:
        return (
          <VStack spacing={4} align="stretch">
            <Heading size="md">Step 3: Process Summary & Visualization</Heading>
            <ProcessSummaryVisualization
              processName={processName}
              processType={processType}
              objectives={objectives}
              involvedDepartments={involvedDepartments}
              selectedRiskCategories={selectedRiskCategories}
              riskPrioritizationData={riskPrioritizationData}
              monitoringFrequency={monitoringFrequency}
              auditFrequency={auditFrequency}
              complianceActivities={complianceActivities}
              correctionPlan={correctionPlan}
              preventionPlan={preventionPlan}
              onFinishAndSave={handleFinishAndSave}
            />
          </VStack>
        );
      default:
        return null;
    }
  };

  const isNextDisabled = () => {
    if (step === -1) {
      return !maturityLevel;
    }
    if (step === 0) {
      return !processType;
    }
    return false;
  };

  return (
    <Box p={8} borderRadius="lg" shadow="xl" maxWidth="4xl" margin="auto" bgGradient={bgGradient}>
      <Heading size="lg" mb={6} color={textColor}>AI-Powered Compliance Process Wizard</Heading>  
      <AnimatePresence mode="wait">
        <motion.div
          key={step}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >
          <Box mb={6} bg={boxBg} p={6} borderRadius="md" shadow="md">
            {renderStep()}
          </Box>
        </motion.div>
      </AnimatePresence>
      <HStack justifyContent="space-between">
        {step > -1 && (
          <Button 
            onClick={handleBack} 
            leftIcon={<ArrowLeft />} 
            colorScheme="purple" 
            variant="outline"
            isDisabled={isTransitioning}
          >
            Back
          </Button>
        )}
        <Button 
          onClick={() => toast({ title: "Draft Saved", description: "Your progress has been saved as a draft.", status: "success", duration: 2000 })} 
          leftIcon={<Save />} 
          colorScheme="blue" 
          variant="outline"
        >
          Save Draft
        </Button>
        {step < 3 ? (
          <Button 
            onClick={handleNext} 
            rightIcon={<ArrowRight />} 
            colorScheme="purple"
            isLoading={isTransitioning}
            loadingText="Processing"
            isDisabled={isNextDisabled()}
          >
            Next
          </Button>
        ) : null}
      </HStack>
      {isTransitioning && (
        <Box position="fixed" top="0" left="0" right="0" bottom="0" bg="rgba(0,0,0,0.5)" zIndex="overlay" display="flex" alignItems="center" justifyContent="center">
          <VStack spacing={4} bg={boxBg} p={6} borderRadius="md" shadow="xl">
            <Spinner size="xl" />
            <Text>Analyzing and updating process design...</Text>
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default ComplianceProcessWizard;
                