import React from 'react';
import { Sparkles } from 'lucide-react';

const AIAnalyzeButton = ({ onClick, item }) => (
  <button
    onClick={() => onClick(item)}
    className="flex items-center justify-center px-3 py-1 bg-purple-600 text-white rounded hover:bg-purple-700 transition-colors"
  >
    <Sparkles size={16} className="mr-2" />
    AI Analyze
  </button>
);

export default AIAnalyzeButton;