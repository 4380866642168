import React from 'react';
import { DollarSign, Clock, ShieldCheck, TrendingUp, UserCheck, Globe } from 'lucide-react';

const ValueCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white p-6 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-105">
    <div className="flex items-center justify-center h-16 w-16 rounded-full bg-blue-100 mx-auto mb-4">
      <Icon className="h-8 w-8 text-blue-600" />
    </div>
    <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const ValueProposition = ({ sectionRef }) => (
  <section ref={sectionRef} className="mb-24 py-12 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-2xl">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <p className="text-xl text-center mb-12">
        Here's how our platform can help you:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <ValueCard
          icon={Clock}
          title="Design Processes in Minutes"
          description="Our AI-powered Compliance Process Wizard creates tailored workflows in minutes, not months, dramatically reducing design and implementation time."
        />
        <ValueCard
          icon={DollarSign}
          title="Optimize Compliance Costs"
          description="Reduce reliance on external consultants. Our system empowers your team to manage complex compliance tasks efficiently."
        />
        <ValueCard
          icon={ShieldCheck}
          title="Enhance Risk Management"
          description="AI-driven real-time risk assessment and mitigation strategies keep you ahead of regulatory changes."
        />
        <ValueCard
          icon={TrendingUp}
          title="Boost Operational Efficiency"
          description="Streamline compliance processes, allowing your team to focus on innovation and growth."
        />
        <ValueCard
          icon={UserCheck}
          title="Empower Your Team"
          description="Intuitive interface and AI assistance make compliance accessible to your entire organization."
        />
        <ValueCard
          icon={Globe}
          title="Ensure Global Compliance"
          description="Manage multi-jurisdictional compliance with confidence, maintaining consistency across global operations."
        />
      </div>
    </div>
  </section>
);

export default ValueProposition;