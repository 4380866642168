import React, { forwardRef } from 'react';
import { Brain, Clock, Sparkles } from 'lucide-react';

const WizardFeature = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
    <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
      <Icon className="w-8 h-8 text-blue-600" />
    </div>
    <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
    <p className="text-gray-600 text-center">{description}</p>
  </div>
);

const WizardProcessFeature = forwardRef((props, ref) => (
  <section ref={ref} className="my-16 pt-16 -mt-16"> {/* Added pt-16 and -mt-16 */}
    <h2 className="text-4xl font-bold text-center mb-8 text-gradient leading-relaxed">
      Compliance Process Wizard: Design at the Speed of Thought
    </h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      <WizardFeature
        icon={Brain}
        title="AI-Powered Process Design"
        description="Generate tailored compliance processes using cutting-edge AI technology, adapting to your specific industry needs."
      />
      <WizardFeature
        icon={Clock}
        title="Rapid Implementation"
        description="Reduce process design time from months to minutes, allowing for quick adaptation to new regulations."
      />
      <WizardFeature
        icon={Sparkles}
        title="Industry-Specific Templates"
        description="Access pre-built templates tailored for healthcare and pharmaceutical compliance, including AI governance."
      />
    </div>
  </section>
));

export default WizardProcessFeature;