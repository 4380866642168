import React, { useState, forwardRef } from 'react';
import { Globe, Brain, FileText, Cpu, ChevronDown, ChevronUp, Zap, ShieldCheck, DollarSign, Clock, Target, TrendingUp, Shield, Users } from 'lucide-react';

const DetailedUseCase = ({ title, icon: Icon, challenge, solution, impact }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-8 border rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl">
      <div 
        className="flex items-center justify-between p-4 bg-blue-50 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <Icon size={24} className="text-blue-600 mr-2" />
          <h3 className="text-xl font-semibold">{title}</h3>
        </div>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </div>
      {isOpen && (
        <div className="p-4">
          <h4 className="font-semibold mb-2">Challenge:</h4>
          <p className="mb-4">{challenge}</p>
          <h4 className="font-semibold mb-2">LOKATIAL Solution:</h4>
          <ul className="list-disc pl-5 mb-4">
            {solution.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <h4 className="font-semibold mb-2">Impact:</h4>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {impact.map((item, index) => (
              <div key={index} className="flex items-center">
                <item.icon size={20} className="text-blue-600 mr-2" />
                <span>{item.text}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const UseCases = forwardRef((props, ref) => (
    <section ref={ref} className="py-12 bg-white pt-24 -mt-12 border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-extrabold text-center mb-12 text-gradient">
          LOKATIAL in Action
        </h2>  
      <div className="space-y-8">
        <DetailedUseCase
          title="Global Pharma: AI-Powered Regulatory Intelligence and Compliance"
          icon={Globe}
          challenge="Staying compliant with rapidly evolving regulations across multiple jurisdictions while managing complex clinical trials and drug development processes."
          solution={[
            "AI-powered Compliance Process Wizard for intelligent workflow creation and adaptation",
            "Real-time regulatory intelligence integration with AI-driven updates and impact analysis",
            "Automated policy review and gap analysis using natural language processing",
            "AI-assisted risk assessment and mitigation strategies",
            "Machine learning-based predictive compliance forecasting"
          ]}
          impact={[
            { icon: Zap, text: "Up to 75% faster regulatory adaptation" },
            { icon: ShieldCheck, text: "Up to 50% reduction in compliance gaps" },
            { icon: DollarSign, text: "Up to 30% decrease in compliance-related costs" }
          ]}
        />
        <DetailedUseCase
          title="AI Diagnostics Startup: Ethical AI Governance and EU AI Act Compliance"
          icon={Brain}
          challenge="Implementing robust AI governance while ensuring rapid innovation and compliance with the EU AI Act for high-risk AI systems in healthcare."
          solution={[
            "AIMS (AI Management System) for comprehensive AI model lifecycle management",
            "Automated AI risk assessment aligned with EU AI Act requirements",
            "Continuous AI model performance monitoring and bias detection",
            "AI-powered explainability tools for transparent decision-making",
            "Automated documentation generation for regulatory submissions"
          ]}
          impact={[
            { icon: Zap, text: "Up to 40% accelerated time-to-market for AI products" },
            { icon: ShieldCheck, text: "Up to 60% enhanced EU AI Act compliance readiness" },
            { icon: Users, text: "Up to 50% increased trust from healthcare providers and patients" }
          ]}
        />
        <DetailedUseCase
          title="Contract Research Organization (CRO): AI-Enhanced GxP Compliance"
          icon={FileText}
          challenge="Maintaining strict GxP compliance across diverse projects and clients while improving efficiency and data integrity in clinical trials."
          solution={[
            "AI-driven Compliance Process Wizard for customizable, GxP-compliant process templates",
            "Machine learning-powered data anomaly detection and integrity verification",
            "Automated compliance checks with AI-assisted root cause analysis",
            "Natural language processing for automated protocol deviation detection",
            "AI-powered trend analysis for continuous process improvement"
          ]}
          impact={[
            { icon: Clock, text: "Up to 50% streamlined audit preparation process" },
            { icon: Target, text: "Up to 40% improved accuracy in regulatory submissions" },
            { icon: TrendingUp, text: "Up to 30% boost in operational efficiency" }
          ]}
        />
        <DetailedUseCase
          title="Medical Device Manufacturer: Integrated Compliance and Quality Management"
          icon={Cpu}
          challenge="Harmonizing compliance processes across product lifecycle, from design to post-market surveillance, while adapting to evolving regulatory landscapes like EU MDR and IVDR."
          solution={[
            "AI-powered design control and risk management integration",
            "Automated regulatory requirement mapping and gap analysis",
            "Machine learning-based post-market surveillance and signal detection",
            "AI-assisted CAPA (Corrective and Preventive Action) management",
            "Predictive analytics for supply chain compliance risks"
          ]}
          impact={[
            { icon: Shield, text: "Up to 60% faster identification of potential non-conformities" },
            { icon: Zap, text: "Up to 35% accelerated time-to-market for new devices" },
            { icon: TrendingUp, text: "Up to 25% overall improvement in product quality and safety" }
          ]}
        />
      </div>
    </div>
  </section>
));

export default UseCases;