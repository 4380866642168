import React, { useState } from 'react';
import {
  Tabs, TabList, TabPanels, Tab, TabPanel, VStack, HStack, Text, Icon, Box, useColorModeValue,
  Tooltip, Button
} from "@chakra-ui/react";
import { RiskPrioritization, RiskAnalysis, ControlDesign, RiskTreatment } from './EnhancedRiskAssessmentComponents';
import { MonitoringPlan, AuditPlanning, AuditExecution, FindingsAndRecommendations, FollowUpAndReporting } from './EnhancedAuditComponents';
import { FaExclamationTriangle, FaSearchPlus, FaShieldAlt, FaFirstAid, FaClipboardCheck, FaCalendarAlt, FaPlay, FaClipboardList, FaChartLine } from 'react-icons/fa';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

// Component for displaying tab title with icon and text
const TabTitle = ({ icon, title }) => (
  <HStack spacing={2}>
    <Icon as={icon} />
    <Text fontSize="md" fontWeight="semibold">{title}</Text>
  </HStack>
);

const FrameworkTabs = ({
  processType, // either 'Risk Assessment' or 'Compliance Monitoring'
  selectedRiskCategories,
  involvedDepartments,
  complianceActivities,
  setComplianceActivities,
  monitoringFrequency,
  setMonitoringFrequency,
  auditFrequency,
  setAuditFrequency,
  correctionPlan,
  setCorrectionPlan,
  preventionPlan,
  setPreventionPlan,
  aiSuggestions,
  riskPrioritizationData,
  setRiskPrioritizationData,
  onRiskPrioritizationChange
}) => {
  const [activeTab, setActiveTab] = useState(0);

  // Theme and Color configurations
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeTabUnderlineColor = useColorModeValue("purple.600", "purple.200");
  const hoverBgColor = useColorModeValue("gray.100", "gray.600");

  // Function to handle tab change
  const handleTabChange = (index) => setActiveTab(index);

  // Function for navigating between tabs
  const navigateTab = (direction) => {
    const newIndex = activeTab + direction;
    const totalTabs = processType === 'Risk Assessment' ? riskAssessmentTabs.length : complianceMonitoringTabs.length;
    if (newIndex >= 0 && newIndex < totalTabs) {
      setActiveTab(newIndex);
    }
  };

  // Define tabs and components for 'Risk Assessment'
  const riskAssessmentTabs = [
    { title: "Risk Prioritization", component: RiskPrioritization, icon: FaExclamationTriangle, props: { selectedRiskCategories, involvedDepartments, aiSuggestions, riskData: riskPrioritizationData, onDataChange: onRiskPrioritizationChange } },
    { title: "Detailed Analysis", component: RiskAnalysis, icon: FaSearchPlus, props: { selectedRiskCategories, involvedDepartments, aiSuggestions, riskPrioritizationData } },
    { title: "Control Design", component: ControlDesign, icon: FaShieldAlt, props: { selectedRiskCategories, involvedDepartments, aiSuggestions } },
    { title: "Treatment", component: RiskTreatment, icon: FaFirstAid, props: { selectedRiskCategories, involvedDepartments, aiSuggestions } }
  ];

  // Define tabs and components for 'Compliance Monitoring'
  const complianceMonitoringTabs = [
    { title: "Monitoring", component: MonitoringPlan, icon: FaClipboardCheck, props: { monitoringFrequency, setMonitoringFrequency, complianceActivities, setComplianceActivities, involvedDepartments: involvedDepartments || [] } },
    { title: "Audit Planning", component: AuditPlanning, icon: FaCalendarAlt, props: { auditFrequency, setAuditFrequency, complianceActivities, setComplianceActivities, involvedDepartments: involvedDepartments || [] } },
    { title: "Execution", component: AuditExecution, icon: FaPlay, props: { involvedDepartments: involvedDepartments || [] } },
    { title: "Findings", component: FindingsAndRecommendations, icon: FaClipboardList, props: { involvedDepartments: involvedDepartments || [] } },
    { title: "Follow-up", component: FollowUpAndReporting, icon: FaChartLine, props: { correctionPlan, setCorrectionPlan, preventionPlan, setPreventionPlan, involvedDepartments: involvedDepartments || [] } }
  ];

  // Render the tab content based on process type
  const renderTabContent = () => {
    const tabContent = processType === 'Risk Assessment' ? riskAssessmentTabs : complianceMonitoringTabs;

    return (
      <Box bg={bgColor} p={4} borderRadius="lg" boxShadow="md" border="1px" borderColor={borderColor}>
        <Tabs index={activeTab} onChange={handleTabChange} variant="unstyled">
          <TabList mb={4}>
            {tabContent.map((tab, index) => (
              <Tab
                key={index}
                _selected={{ color: activeTabUnderlineColor, borderBottom: `2px solid ${activeTabUnderlineColor}` }}
                _hover={{ backgroundColor: hoverBgColor }}
                px={4} py={2}
              >
                <TabTitle icon={tab.icon} title={tab.title} />
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabContent.map((tab, index) => (
              <TabPanel key={index}>
                <VStack spacing={4} align="stretch">
                  <tab.component {...tab.props} />
                  {/* Navigation Buttons */}
                  <HStack justify="space-between" mt={4}>
                    <Tooltip label="Go to the previous tab" placement="top">
                      <Button
                        leftIcon={<ChevronLeftIcon />}
                        onClick={() => navigateTab(-1)}
                        isDisabled={activeTab === 0}
                      >
                        Previous Tab
                      </Button>
                    </Tooltip>
                    <Tooltip label="Go to the next tab" placement="top">
                      <Button
                        rightIcon={<ChevronRightIcon />}
                        onClick={() => navigateTab(1)}
                        isDisabled={activeTab === tabContent.length - 1}
                      >
                        Next Tab
                      </Button>
                    </Tooltip>
                  </HStack>
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    );
  };

  return renderTabContent();
};

export default FrameworkTabs;
