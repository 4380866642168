import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Search, Shield, Lock, FileText, Activity, Brain, Star } from 'lucide-react';

const FrameworkItem = ({ name, tier, icon: Icon }) => {
  const getTierColor = (tier) => {
    switch(tier) {
      case 'critical': return 'text-blue-600';
      case 'high': return 'text-blue-500';
      case 'medium': return 'text-blue-400';
      default: return 'text-gray-400';
    }
  };

  return (
    <div className="flex items-center justify-between p-3 bg-white rounded-lg transition-all duration-300 hover:bg-blue-50 hover:shadow-md">
      <div className="flex items-center">
        <Icon className="h-5 w-5 text-blue-600 mr-2" />
        <span className="text-sm font-medium text-gray-800">{name}</span>
      </div>
      <div className="flex items-center">
        {tier === 'critical' && <Star className={`h-4 w-4 ${getTierColor(tier)} mr-1`} fill="currentColor" />}
        {tier === 'high' && <Star className={`h-4 w-4 ${getTierColor(tier)} mr-1`} />}
        <span className={`text-xs font-semibold ${getTierColor(tier)}`}>
          {tier.charAt(0).toUpperCase() + tier.slice(1)}
        </span>
      </div>
    </div>
  );
};

const FrameworkCluster = ({ title, frameworks, icon: Icon }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const displayFrameworks = isExpanded ? frameworks : frameworks.slice(0, 3);

  return (
    <div className="bg-white p-6 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <Icon className="h-6 w-6 text-blue-600 mr-2" />
          <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
        </div>
        <button
          className="text-blue-600 font-medium flex items-center"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <>
              <ChevronUp className="mr-1" size={16} />
              Show less
            </>
          ) : (
            <>
              <ChevronDown className="mr-1" size={16} />
              Show more
            </>
          )}
        </button>
      </div>
      <div className="grid grid-cols-1 gap-4">
        {displayFrameworks.map((framework, index) => (
          <FrameworkItem key={index} name={framework.name} tier={framework.tier} icon={Shield} />
        ))}
      </div>
    </div>
  );
};

const ComplianceFrameworksSection = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const categories = [
    {
      title: "Compliance Management Systems",
      icon: FileText,
      frameworks: [
        { name: "ISO 37301", tier: "critical" },
        { name: "IDW PS 980", tier: "high" },
        { name: "COSO Framework", tier: "medium" },
        { name: "DOJ Evaluation of Corporate Compliance Programs", tier: "medium" }
      ]
    },
    {
      title: "AI & Ethics",
      icon: Brain,
      frameworks: [
        { name: "EU AI Act", tier: "critical" },
        { name: "NIST AI Risk Management Framework", tier: "high" },
        { name: "IEEE AI Ethics Guidelines", tier: "medium" },
        { name: "OECD AI Principles", tier: "medium" },
        { name: "FDA AI/ML Guidelines", tier: "high" }
      ]
    },
    {
      title: "Healthcare & Pharma Specific",
      icon: Activity,
      frameworks: [
        { name: "HIPAA", tier: "critical" },
        { name: "FDA 21 CFR Part 11", tier: "critical" },
        { name: "GxP", tier: "high" },
        { name: "EU MDR", tier: "high" },
        { name: "ICH GCP", tier: "high" },
        { name: "EMA Regulations", tier: "medium" },
        { name: "CLIA", tier: "medium" }
      ]
    },
    {
      title: "Data Protection & Privacy",
      icon: Lock,
      frameworks: [
        { name: "GDPR", tier: "critical" },
        { name: "CCPA", tier: "high" },
        { name: "PIPEDA", tier: "medium" },
        { name: "HITECH Act", tier: "high" },
        { name: "Privacy Shield", tier: "medium" }
      ]
    }
  ];

  const filteredCategories = categories.map(category => ({
    ...category,
    frameworks: category.frameworks
      .filter(framework => framework.name.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => {
        const tierOrder = { critical: 0, high: 1, medium: 2 };
        return tierOrder[a.tier] - tierOrder[b.tier];
      })
  })).filter(category => category.frameworks.length > 0);

  return (
    <section className="py-16 bg-gradient-to-r from-blue-50 to-purple-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center mb-8 text-gradient leading-relaxed">
          Comprehensive Compliance Coverage
        </h2>
        <p className="text-xl text-gray-600 text-center mb-12 max-w-3xl mx-auto">
          LOKATIAL supports a wide range of compliance frameworks, ensuring your organization stays compliant across multiple regulatory landscapes.
        </p>
        <div className="mb-8 relative">
          <input
            type="text"
            placeholder="Search frameworks..."
            className="w-full p-3 pl-10 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-3 text-gray-400" size={20} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {filteredCategories.map((category, index) => (
            <FrameworkCluster
              key={index}
              title={category.title}
              frameworks={category.frameworks}
              icon={category.icon}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ComplianceFrameworksSection;