import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Users, Shield, FileText, Settings, Bell, Upload, User, ExternalLink, MessageSquare, Activity, Cog, BarChart2, TrendingUp, Lock, AlertTriangle, LifeBuoy, XCircle, Sparkles, Book, HelpCircle, CheckSquare, Clock, Cpu, Database, PlusCircle, Brain, FileIcon} from 'lucide-react';
import { LineChart, Line, BarChart, Bar, XAxis, Tooltip, YAxis, CartesianGrid, Legend, ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';
import { Button } from './ui/Button';
import { Progress } from './ui/Progress';
import PolicyList from './PolicyList.js';
import WorkflowList from './WorkflowList.js';
import ChakraWizardWrapper from './ChakraWizardWrapper';
import AIAnalyzeButton from './AIAnalyzeButton';
import AnalysisModal from './AnalysisModal';
import AIBestPractices from './AIBestPractices';
import AIComplianceTrend from './AIComplianceTrend';
import EnhancedOverview from './EnhancedOverview';

const appVersion = process.env.REACT_APP_VERSION || '0.4';

const fetchDashboardData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        overallScore: 85,
        thirdPartyRiskScore: 72,
        dataPrivacyScore: 91,
        aiComplianceScore: 78,
        informationSecurityScore: 88,
        antiCorruptionScore: 82,
        complianceTrend: [
          { name: 'Jan', score: 65 },
          { name: 'Feb', score: 68 },
          { name: 'Mar', score: 75 },
          { name: 'Apr', score: 78 },
          { name: 'May', score: 82 },
          { name: 'Jun', score: 85 },
        ],
        policies: [
          { id: 1, name: 'Data Protection Policy', lastUpdated: '2023-05-15' },
          { id: 2, name: 'Third-Party Risk Management Policy', lastUpdated: '2023-04-22' },
          { id: 3, name: 'Information Security Policy', lastUpdated: '2023-06-01' },
          { id: 4, name: 'AI Ethics Policy', lastUpdated: '2023-07-10' },
          { id: 5, name: 'Anti-Corruption Policy', lastUpdated: '2023-06-20' },
        ],
        workflows: [
          { id: 1, name: 'Data Breach Response Workflow', lastUpdated: '2023-05-20' },
          { id: 2, name: 'Vendor Onboarding Workflow', lastUpdated: '2023-04-30' },
          { id: 3, name: 'AI Model Approval Workflow', lastUpdated: '2023-07-05' },
          { id: 4, name: 'Information Security Incident Response', lastUpdated: '2023-06-15' },
          { id: 5, name: 'Anti-Corruption Due Diligence', lastUpdated: '2023-06-25' },
        ],
        riskHeatMapData: [
          { area: 'Data Privacy', value: 80 },
          { area: 'Third-Party Risk', value: 65 },
          { area: 'AI Ethics', value: 70 },
          { area: 'Regulatory Compliance', value: 85 },
          { area: 'Information Security', value: 75 },
          { area: 'Financial Compliance', value: 90 },
        ],
        aiComplianceTrendData: [
          { month: 'Jan', predicted: 75, actual: 72 },
          { month: 'Feb', predicted: 78, actual: 76 },
          { month: 'Mar', predicted: 82, actual: 80 },
          { month: 'Apr', predicted: 85, actual: 83 },
          { month: 'May', predicted: 88, actual: 87 },
          { month: 'Jun', predicted: 92, actual: 90 },
        ],
        thirdPartyRiskAnalytics: {
          vendorRiskDistribution: [
            { risk: 'Low', count: 150 },
            { risk: 'Medium', count: 80 },
            { risk: 'High', count: 20 },
          ],
          assessmentsCompleted: 250,
        },
        dataPrivacyAnalytics: {
          subjectRequests: [
            { month: 'Jan', requests: 12 },
            { month: 'Feb', requests: 19 },
            { month: 'Mar', requests: 15 },
            { month: 'Apr', requests: 21 },
            { month: 'May', requests: 25 },
          ],
          breachRiskScore: 75,
        },
        aiComplianceAnalytics: {
          modelPerformance: [
            { model: 'Model A', accuracy: 92, fairness: 88 },
            { model: 'Model B', accuracy: 89, fairness: 91 },
            { model: 'Model C', accuracy: 95, fairness: 87 },
          ],
          ethicalConcerns: 5,
        },
        integrations: [
          { name: 'OneTrust', status: 'Connected', type: 'Data Privacy' },
          { name: 'Aravo', status: 'Connected', type: 'Third Party Risk' },
          { name: 'IBM OpenPages', status: 'Disconnected', type: 'GRC' },
          { name: 'Archer', status: 'Connected', type: 'Integrated Risk Management' },
        ],
      });
    }, 1000);
  });
};

const ISOCategoryContent = ({ category }) => {
  const [activeTab, setActiveTab] = useState('overview');
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const categoryContent = {
    leadership: {
      overview: "Leadership commitment is crucial for an effective compliance management system. This section focuses on top management's role in demonstrating leadership and commitment to the CMS.",
      keyPoints: [
        "Compliance policy establishment and communication",
        "Integration of compliance requirements into business processes",
        "Provision of adequate resources for CMS",
        "Promotion of continuous improvement"
      ],
      questionnaire: [
        "Has top management demonstrated leadership and commitment to the CMS?",
        "Is there a documented compliance policy that is appropriate to the organization?",
        "Are compliance objectives established for relevant functions and levels?",
        "Is compliance integrated into the organization's business processes?"
      ],
      quiz: [
        {
          question: "Who is primarily responsible for demonstrating leadership and commitment to the CMS?",
          options: ["Top management", "Compliance officer", "Board of directors", "External auditor"],
          correctAnswer: 0
        },
        // Add more quiz questions
      ],
      recentArticles: [
        { title: "The Role of Leadership in Building a Strong Compliance Culture", url: "#" },
        { title: "How Top Management Can Drive Compliance Success", url: "#" }
      ]
    },
    planning: {
      overview: "Planning involves determining the risks and opportunities that need to be addressed to ensure the CMS can achieve its intended outcomes, prevent or reduce undesired effects, and achieve continual improvement.",
      keyPoints: [
        "Risk assessment process",
        "Compliance obligations identification",
        "Planning of actions to address risks and opportunities",
        "Compliance objectives and planning to achieve them"
      ],
      questionnaire: [
        "Has the organization conducted a comprehensive compliance risk assessment?",
        "Are compliance obligations clearly identified and documented?",
        "Are there documented plans to address identified risks and opportunities?",
        "Have measurable compliance objectives been established?"
      ],
      quiz: [
        {
          question: "What is the primary purpose of a compliance risk assessment?",
          options: ["To identify potential compliance violations", "To determine the effectiveness of controls", "To identify and evaluate compliance risks", "All of the above"],
          correctAnswer: 3
        },
        // Add more quiz questions
      ],
      recentArticles: [
        { title: "Best Practices in Compliance Risk Assessment", url: "#" },
        { title: "Setting Effective Compliance Objectives: A Practical Guide", url: "#" }
      ]
    },
    operation: {
      overview: "Operation focuses on the implementation of the compliance management system, including operational planning and control.",
      keyPoints: [
        "Operational planning and control",
        "Establishing controls and procedures",
        "Outsourced processes",
        "Raising concerns and reporting non-compliances"
      ],
      questionnaire: [
        "Are operational processes related to compliance risks identified and controlled?",
        "Are there procedures in place for raising compliance concerns?",
        "How are outsourced processes managed from a compliance perspective?",
        "Is there a system for reporting and investigating non-compliances?"
      ],
      quiz: [
        {
          question: "What is a key aspect of operational control in a CMS?",
          options: ["Financial reporting", "Procedure documentation", "Marketing strategies", "Product design"],
          correctAnswer: 1
        },
        // Add more quiz questions
      ],
      recentArticles: [
        { title: "Best Practices in Compliance Operational Controls", url: "#" },
        { title: "Managing Outsourced Processes in Compliance Programs", url: "#" }
      ]
    },

    performance: {
      overview: "Performance evaluation is crucial for assessing the effectiveness of the compliance management system. It involves monitoring, measurement, analysis, and evaluation of the CMS.",
      keyPoints: [
        "Monitoring and measuring compliance performance",
        "Compliance reporting",
        "Internal audit",
        "Management review of the CMS"
      ],
      questionnaire: [
        "Are there established processes for monitoring and measuring compliance performance?",
        "How frequently are compliance reports generated and reviewed?",
        "Is there a systematic approach to conducting internal audits of the CMS?",
        "Does top management regularly review the effectiveness of the CMS?"
      ],
      quiz: [
        {
          question: "What is a key component of performance evaluation in a CMS?",
          options: ["Financial auditing", "Product quality checks", "Internal compliance audits", "Customer satisfaction surveys"],
          correctAnswer: 2
        },
        {
          question: "How often should management review the CMS?",
          options: ["Daily", "Weekly", "Monthly", "At planned intervals (e.g., annually)"],
          correctAnswer: 3
        }
      ],
      recentArticles: [
        { title: "Key Performance Indicators for Compliance Management Systems", url: "#" },
        { title: "Effective Compliance Reporting: What Management Needs to Know", url: "#" }
      ]
    },
    
    improvement: {
      overview: "Continuous improvement is essential for maintaining an effective compliance management system. It involves identifying non-conformities, taking corrective actions, and continually enhancing the CMS.",
      keyPoints: [
        "Identifying and addressing non-conformities",
        "Continual improvement of the CMS",
        "Updating the CMS based on changes in the organization or its context",
        "Managing changes in compliance obligations"
      ],
      questionnaire: [
        "Is there a process for identifying and addressing non-conformities in the CMS?",
        "How does the organization promote and implement continual improvement of the CMS?",
        "Are there mechanisms to update the CMS based on internal and external changes?",
        "How are changes in compliance obligations identified and incorporated into the CMS?"
      ],
      quiz: [
        {
          question: "What is a key driver for continuous improvement in a CMS?",
          options: ["Maintaining the status quo", "Addressing non-conformities", "Reducing staff", "Increasing profits"],
          correctAnswer: 1
        },
        {
          question: "How should an organization respond to changes in compliance obligations?",
          options: ["Ignore them", "Only update policies", "Update the entire CMS as necessary", "Wait for an audit to identify issues"],
          correctAnswer: 2
        }
      ],
      recentArticles: [
        { title: "Strategies for Continuous Improvement in Compliance Management", url: "#" },
        { title: "Adapting Your CMS to Evolving Regulatory Landscapes", url: "#" }
      ]
    },
    
  };

  const content = categoryContent[category];

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setCurrentQuestion(0);
  };

  const handleNextQuestion = () => {
    if (currentQuestion < content.quiz.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setQuizStarted(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-4 rounded shadow">
        <h2 className="text-xl font-semibold mb-4">{category.charAt(0).toUpperCase() + category.slice(1)}</h2>
        <div className="flex space-x-4 mb-4">
          {['overview', 'questionnaire', 'quiz', 'resources'].map((tab) => (
            <button
              key={tab}
              className={`px-3 py-2 rounded ${activeTab === tab ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
        {activeTab === 'overview' && (
          <div>
            <p className="mb-4">{content.overview}</p>
            <h3 className="font-semibold mb-2">Key Points:</h3>
            <ul className="list-disc pl-5">
              {content.keyPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        )}
        {activeTab === 'questionnaire' && (
          <div>
            <h3 className="font-semibold mb-2">AI-Powered Questionnaire:</h3>
            <ul className="space-y-4">
              {content.questionnaire.map((question, index) => (
                <li key={index} className="flex items-start">
                  <CheckSquare className="mr-2 mt-1 flex-shrink-0" size={20} />
                  <span>{question}</span>
                </li>
              ))}
            </ul>
            <button className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
              Generate Report
            </button>
          </div>
        )}
        {activeTab === 'quiz' && (
          <div>
            {!quizStarted ? (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={handleStartQuiz}
              >
                Start Quiz
              </button>
            ) : (
              <div>
                <h3 className="font-semibold mb-2">Question {currentQuestion + 1}:</h3>
                <p className="mb-4">{content.quiz[currentQuestion].question}</p>
                <ul className="space-y-2">
                  {content.quiz[currentQuestion].options.map((option, index) => (
                    <li key={index}>
                      <label className="flex items-center">
                        <input type="radio" name="answer" className="mr-2" />
                        {option}
                      </label>
                    </li>
                  ))}
                </ul>
                <button
                  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  onClick={handleNextQuestion}
                >
                  {currentQuestion < content.quiz.length - 1 ? 'Next Question' : 'Finish Quiz'}
                </button>
              </div>
            )}
          </div>
        )}
        {activeTab === 'resources' && (
          <div>
            <h3 className="font-semibold mb-2">Recent Articles:</h3>
            <ul className="space-y-2">
              {content.recentArticles.map((article, index) => (
                <li key={index}>
                  <a href={article.url} className="text-blue-500 hover:underline flex items-center">
                    <Book className="mr-2" size={16} />
                    {article.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const DashboardWireframe = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState('overview');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [showWizard, setShowWizard] = useState(false);
  const [newProcesses, setNewProcesses] = useState([]);

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_DASHBOARDS !== 'true') {
      return;
    }
    fetchDashboardData().then((data) => {
      setDashboardData(data);
      setLoading(false);
    });
  }, []);

  if (process.env.REACT_APP_ENABLE_DASHBOARDS !== 'true') {
    return <Navigate to="/" />;
  }

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  const navigationItems = [
    { name: 'overview', icon: Users, category: 'main', status: 'active' },
    {
      name: 'newProcess', 
      icon: Brain, 
      category: 'main', 
      status: 'active',
      render: ({ onClick }) => (
        <div className="relative group">
          <button 
            onClick={onClick}
            className="flex items-center w-full px-4 py-2 text-left text-purple-700 font-semibold hover:bg-purple-100 transition-all duration-300 pulse-animation"
          >
            <Brain className="mr-3 flex-shrink-0" size={24} />
            <span className="flex-grow truncate">New Process</span>
            <Sparkles className="ml-2 flex-shrink-0" size={16} />
          </button>
          <div className="absolute left-full ml-2 p-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
            Create a new compliance process with AI assistance
          </div>
        </div>
      )
    },
  
    { name: 'leadership', icon: Shield, category: 'iso', status: 'active' },
    { name: 'planning', icon: Activity, category: 'iso', status: 'active' },
    { name: 'operation', icon: Cog, category: 'iso', status: 'active' },
    { name: 'performance', icon: BarChart2, category: 'iso', status: 'active' },
    { name: 'improvement', icon: TrendingUp, category: 'iso', status: 'active' },
    { name: 'thirdPartyRisk', icon: Users, category: 'processes', status: 'active' },
    { name: 'dataPrivacy', icon: Lock, category: 'processes', status: 'active' },
    { name: 'informationSecurity', icon: Shield, category: 'processes', status: 'in_development' },
    { name: 'antiCorruption', icon: AlertTriangle, category: 'processes', status: 'in_development' },
    { name: 'aiCompliance', icon: Cpu, category: 'processes', status: 'active' },
    { name: 'integrations', icon: Database, category: 'support', status: 'active' },
    { name: 'support', icon: LifeBuoy, category: 'support', status: 'active' },
    { name: 'settings', icon: Settings, category: 'support', status: 'active' },
    ...newProcesses.map(process => ({
      name: process.name,
      icon: process.icon,
      category: 'newProcesses',
      status: 'draft'
    }))
  ];

  const handleUpload = (type) => {
    console.log(`Uploading new ${type}`);
    const uploadContent = (
      <div className="text-center">
        <h3 className="text-xl font-semibold mb-4">Upload {type.charAt(0).toUpperCase() + type.slice(1)}</h3>
        <p className="mb-4">Upload functionality will be implemented here.</p>
        <div className="text-sm text-gray-600 mb-4 text-left">
          <p className="mb-2">Your data security is our top priority:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>All uploads and AI analyses occur on dedicated, encrypted servers specific to your organization.</li>
            <li>We employ end-to-end encryption for data in transit and at rest.</li>
            <li>Our AI models are trained exclusively on proprietary data, never on customer information.</li>
            <li>We maintain strict data isolation using advanced containerization technologies.</li>
            <li>Regular third-party security audits ensure the highest standards of data protection.</li>
          </ul>
        </div>
        <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Placeholder Upload Button
        </button>
      </div>
    );
    setModalContent(uploadContent);
    setModalOpen(true);
  };

  const handleAnalyze = (item) => {
    console.log(`Analyzing ${item.name}`);
    let analysisData = {
      name: item.name,
      type: item.name.includes('Workflow') ? 'workflow' : 'policy',
      gapAnalysis: [],
      standardAlignment: {},
      aiInsights: {}
    };
  
    if (item.name.includes('Workflow')) {
      analysisData.gapAnalysis = [
        'Reduce approval steps from 4 to 3 to streamline the process',
        'Implement automated risk scoring for initial vendor assessment',
        'Add a step for periodic re-assessment of long-term vendors'
      ];
      analysisData.aiInsights = {
        processEfficiency: '78%',
        suggestedKPIs: [
          'Average time to onboard a new vendor',
          'Percentage of vendors passing initial risk assessment',
          'Number of compliance incidents related to third-party vendors'
        ]
      };
    } else {
      analysisData.gapAnalysis = [
        'Section 3.2 needs updating to reflect latest regulations',
        'Consider adding a clause about data retention periods'
      ];
      analysisData.aiInsights = {
        readinessCheck: '85% compliant',
        suggestedImprovements: 'AI-suggested improvements would be listed here.'
      };
    }
  
    analysisData.standardAlignment = {
      iso37301: 'Sections 4.5, 6.2, and 7.4 need attention',
      idwPs980: 'Aligns well, minor updates needed in risk assessment process'
    };
  
    const analysisContent = (
      <div className="text-left">
        <h3 className="text-xl font-semibold mb-4">AI Analysis: {analysisData.name}</h3>
        
        <div className="mb-4">
          <h4 className="font-semibold mb-2">Gap Analysis:</h4>
          <ul className="list-disc pl-5 space-y-1">
            {analysisData.gapAnalysis.map((gap, index) => (
              <li key={index}>{gap}</li>
            ))}
          </ul>
        </div>
  
        <div className="mb-4">
          <h4 className="font-semibold mb-2">Standard Alignment:</h4>
          <ul className="list-disc pl-5 space-y-1">
            {Object.entries(analysisData.standardAlignment).map(([standard, alignment]) => (
              <li key={standard}><strong>{standard.toUpperCase()}:</strong> {alignment}</li>
            ))}
          </ul>
        </div>
  
        <div className="mb-4">
          <h4 className="font-semibold mb-2">AI Insights:</h4>
          {analysisData.type === 'workflow' ? (
            <>
              <p>Process Efficiency: {analysisData.aiInsights.processEfficiency}</p>
              <p>Suggested KPIs:</p>
              <ul className="list-disc pl-5 space-y-1">
                {analysisData.aiInsights.suggestedKPIs.map((kpi, index) => (
                  <li key={index}>{kpi}</li>
                ))}
              </ul>
            </>
          ) : (
            <>
              <p>Readiness Check: {analysisData.aiInsights.readinessCheck}</p>
              <p>Suggested Improvements: {analysisData.aiInsights.suggestedImprovements}</p>
            </>
          )}
        </div>
  
        <div className="text-sm text-gray-600 mb-4">
          <p className="mb-2">Our AI analysis ensures:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Real-time analysis using our proprietary AI models</li>
            <li>Secure processing on isolated, encrypted servers dedicated to your organization</li>
            <li>No data retention post-analysis for maximum privacy</li>
            <li>Compliance with GDPR, CCPA, and other data protection regulations</li>
          </ul>
        </div>
  
        <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Export Full Analysis (Placeholder)
        </button>
      </div>
    );
  
    setModalContent(analysisContent);
    setModalOpen(true);
  };

  const handleNavigation = (name) => {
    if (name === 'newProcess') {
      setShowWizard(true);
    } else {
      setActiveSection(name);
      setShowWizard(false);
    }
  };

  const handleWizardClose = () => {
    setShowWizard(false);
  };
  const formatNavItemName = (name) => {
    if (!name) return ''; // Return empty string if name is undefined
    return name
      .split(/(?=[A-Z])/)
      .map(word => {
        if (word.toLowerCase() === 'ai') {
          return 'AI';
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      })
      .join(' ');
  };
  const handleWizardSave = (processData) => {
    console.log('New process saved:', processData);
    setShowWizard(false);
    
    setNewProcesses(prevProcesses => [...prevProcesses, {
      name: processData.processType, // Use the process type from the wizard
      displayName: processData.name, // This is the custom name if provided
      icon: FileIcon,
      category: 'newProcesses',
      status: 'draft'
    }]);
  };

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left Sidebar */}
      <div className="w-64 bg-white shadow-md">
        <div className="p-4">
          <Link to="/" className="text-xl font-bold hover:text-blue-600 transition duration-300">
            LOKATIAL CMS
          </Link>
        </div>
        <nav className="mt-4">
          {['main', 'iso', 'processes', 'support', 'newProcesses'].map((category) => (
            <div key={category}>
              {category !== 'main' && <hr className="my-2 border-gray-200" />}
              {category === 'newProcesses' && newProcesses.length > 0 && (
                <div className="px-4 py-2 text-sm font-semibold text-gray-600">New Processes (Draft)</div>
              )}
              {navigationItems
                .filter((item) => item.category === category)
                .map((item) => {
                  const { name, icon: Icon, status, render, displayName } = item;
                  return render ? render({
                    onClick: () => status === 'active' && handleNavigation(name)
                  }) : (
                    <button 
                      key={name}
                      className={`flex items-center w-full px-4 py-2 text-left ${
                        activeSection === name ? 'bg-blue-100 text-blue-600' : 'text-gray-700 hover:bg-gray-100'
                      } ${status === 'in_development' ? 'text-gray-400' : ''} ${status === 'draft' ? 'text-orange-500' : ''}`}
                      onClick={() => status === 'active' && handleNavigation(name)}
                      disabled={status !== 'active'}
                    >
                      <Icon className="mr-3 flex-shrink-0" size={20} />
                      <span className="flex-grow truncate">
                        {displayName || formatNavItemName(name)}
                      </span>
                      {status === 'in_development' && (
                        <span className="flex items-center text-xs text-gray-400 ml-2">
                          <Clock size={12} className="mr-1" />
                          In Dev
                        </span>
                      )}
                      {status === 'draft' && (
                        <span className="flex items-center text-xs text-orange-500 ml-2">
                          Draft
                        </span>
                      )}
                    </button>
                  );
                })}
            </div>
          ))}
        </nav>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 overflow-hidden">
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="flex items-center justify-between px-4 py-3">
            <h1 className="text-xl font-semibold">Continuous Compliance Management</h1>
            <div className="flex items-center">
              <Link to="/aims-dashboard" className="mr-4 text-blue-500 hover:text-blue-600 flex items-center">
                <Cpu size={16} className="mr-1" />
                AIMS Dashboard
              </Link>
              <span className="mr-4 text-sm text-gray-500">v{appVersion}</span>
              <Bell className="mr-4 cursor-pointer" size={20} />
              <User className="mr-2" size={20} />
              <span>Tarun S.</span>
            </div>
          </div>
        </header>

        {/* Dashboard Content */}
        <main className="p-6 overflow-auto" style={{ height: 'calc(100vh - 64px)' }}>
          {activeSection === 'overview' && dashboardData && (
            <EnhancedOverview dashboardData={dashboardData} />
          )}

          {['leadership', 'planning', 'operation', 'performance', 'improvement'].includes(activeSection) && (
            <ISOCategoryContent category={activeSection} />
          )}
          
          {['informationSecurity', 'antiCorruption'].includes(activeSection) && (
            <div className="p-4 bg-white rounded shadow">
              <h2 className="text-xl font-semibold mb-4">{activeSection.charAt(0).toUpperCase() + activeSection.slice(1).replace(/([A-Z])/g, ' $1')}</h2>
              <p>This section would contain specific information and tools related to {activeSection.replace(/([A-Z])/g, ' $1').toLowerCase()}.</p>
            </div>
          )}

{activeSection === 'thirdPartyRisk' && (
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <div className="p-4 bg-white rounded shadow">
                  <h2 className="text-xl font-semibold mb-4">Third Party Risk Management</h2>
                  <p className="mb-4">Connect to your existing TPRM tool for detailed vendor management and KYC processes.</p>
                  <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center">
                    <ExternalLink size={16} className="mr-2" /> Connect to TPRM Tool
                  </button>
                </div>
                <div className="p-4 bg-white rounded shadow">
                  <h3 className="text-lg font-semibold mb-2">API Connectors</h3>
                  <p className="mb-2">Integrate with industry-standard TPRM tools:</p>
                  <ul className="list-disc pl-5 space-y-2 mb-4">
                    <li>Aravo</li>
                    <li>Coupa Risk Assess</li>
                    <li>Prevalent</li>
                  </ul>
                  <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center">
                    <Cpu size={16} className="mr-2" /> Manage API Connections
                  </button>
                </div>
              </div>
              <PolicyList 
                policies={dashboardData.policies.filter(p => p.name.includes('Third-Party'))} 
                onUpload={() => handleUpload('policy')} 
                onAnalyze={handleAnalyze}
              />
              <WorkflowList 
                workflows={dashboardData.workflows.filter(w => w.name.includes('Vendor'))} 
                onUpload={() => handleUpload('workflow')} 
                onAnalyze={handleAnalyze}
              />
              <div className="p-4 bg-white rounded shadow">
                <h3 className="text-lg font-semibold mb-4">Third Party Risk Analytics</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h4 className="font-semibold mb-2">Vendor Risk Distribution</h4>
                    <ResponsiveContainer width="100%" height={200}>
                      <BarChart data={dashboardData.thirdPartyRiskAnalytics.vendorRiskDistribution}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="risk" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="count" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div>
                    <h4 className="font-semibold mb-2">Assessments Completed</h4>
                    <div className="flex items-center justify-center h-full">
                      <div className="text-4xl font-bold text-blue-600">
                        {dashboardData.thirdPartyRiskAnalytics.assessmentsCompleted}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

{activeSection === 'dataPrivacy' && dashboardData && dashboardData.dataPrivacyAnalytics && (
  <div className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <div className="p-4 bg-white rounded shadow">
                  <h2 className="text-xl font-semibold mb-4">Data Privacy & Protection</h2>
                  <p className="mb-4">Connect to your existing data privacy management tool for detailed privacy impact assessments and data mapping.</p>
                  <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center">
                    <ExternalLink size={16} className="mr-2" /> Connect to Enterprise Tool
                  </button>
                </div>
                <div className="p-4 bg-white rounded shadow">
                  <h3 className="text-lg font-semibold mb-2">API Connectors</h3>
                  <p className="mb-2">Integrate with industry-standard privacy tools:</p>
                  <ul className="list-disc pl-5 space-y-2 mb-4">
                    <li>OneTrust</li>
                    <li>BigID</li>
                    <li>TrustArc</li>
                  </ul>
                  <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center">
                    <Cpu size={16} className="mr-2" /> Manage API Connections
                  </button>
                </div>
              </div>
              <PolicyList 
                policies={dashboardData.policies.filter(p => p.name.includes('Data') || p.name.includes('Privacy'))} 
                onUpload={() => handleUpload('policy')} 
                onAnalyze={handleAnalyze}
              />
              <WorkflowList 
                workflows={dashboardData.workflows.filter(w => w.name.includes('Data') || w.name.includes('Privacy'))} 
                onUpload={() => handleUpload('workflow')} 
                onAnalyze={handleAnalyze}
              />
              <div className="p-4 bg-white rounded shadow">
                <h3 className="text-lg font-semibold mb-4">Data Privacy Analytics</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h4 className="font-semibold mb-2">Data Subject Requests</h4>
                    <ResponsiveContainer width="100%" height={200}>
                      <BarChart data={dashboardData.dataPrivacyAnalytics.subjectRequests}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="requests" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div>
                    <h4 className="font-semibold mb-2">Data Breach Risk Score</h4>
                    <div className="flex items-center justify-center h-full">
                      <div className="relative w-48 h-48">
                        <svg className="w-full h-full" viewBox="0 0 100 100">
                          <circle
                            cx="50"
                            cy="50"
                            r="45"
                            fill="none"
                            stroke="#e0e0e0"
                            strokeWidth="10"
                          />
                          <circle
                            cx="50"
                            cy="50"
                            r="45"
                            fill="none"
                            stroke="#4CAF50"
                            strokeWidth="10"
                            strokeDasharray={`${dashboardData.dataPrivacyAnalytics.breachRiskScore * 2.83}, 283`}
                            transform="rotate(-90 50 50)"
                          />
                          <text
                            x="50"
                            y="50"
                            textAnchor="middle"
                            dy=".3em"
                            className="text-3xl font-bold"
                            fill="#333"
                          >
                            {dashboardData.dataPrivacyAnalytics.breachRiskScore}%
                          </text>
                        </svg>
                        </div>
                      </div>
                      <p className="text-center mt-2 text-sm text-gray-600">Data Breach Risk</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {activeSection === 'aiCompliance' && dashboardData && dashboardData.aiComplianceAnalytics && (
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <div className="p-4 bg-white rounded shadow">
                  <h2 className="text-xl font-semibold mb-4">AI Compliance Management</h2>
                  <p className="mb-4">Connect to your AI governance platform for comprehensive model management and ethical AI practices.</p>
                  <Link to="/aims-dashboard" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 inline-flex items-center">
                    <Cpu size={16} className="mr-2" /> Go to AIMS Dashboard
                  </Link>
                </div>
                <div className="p-4 bg-white rounded shadow">
                  <h3 className="text-lg font-semibold mb-2">API Connectors</h3>
                  <p className="mb-2">Integrate with industry-standard AI governance tools:</p>
                  <ul className="list-disc pl-5 space-y-2 mb-4">
                    <li>IBM AI Fairness 360</li>
                    <li>Google's Model Cards</li>
                    <li>Microsoft's Fairlearn</li>
                  </ul>
                  <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center">
                    <Cpu size={16} className="mr-2" /> Manage API Connections
                  </button>
                </div>
              </div>
              <PolicyList 
                policies={dashboardData.policies.filter(p => p.name.includes('AI'))} 
                onUpload={() => handleUpload('policy')} 
                onAnalyze={handleAnalyze}
              />
              <WorkflowList 
                workflows={dashboardData.workflows.filter(w => w.name.includes('AI'))} 
                onUpload={() => handleUpload('workflow')} 
                onAnalyze={handleAnalyze}
              />
              <div className="p-4 bg-white rounded shadow">
              <h3 className="text-lg font-semibold mb-4">AI Compliance Analytics</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h4 className="font-semibold mb-2">Model Performance and Fairness</h4>
                  <ResponsiveContainer width="100%" height={200}>
                    <BarChart data={dashboardData.aiComplianceAnalytics.modelPerformance}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="model" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="accuracy" fill="#8884d8" name="Accuracy" />
                      <Bar dataKey="fairness" fill="#82ca9d" name="Fairness" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div>
                  <h4 className="font-semibold mb-2">Ethical Concerns Raised</h4>
                  <div className="flex items-center justify-center h-full">
                    <div className="text-4xl font-bold text-red-600">
                      {dashboardData.aiComplianceAnalytics.ethicalConcerns}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
          
          {activeSection === 'integrations' && (
            <div className="space-y-6">
              <div className="p-4 bg-white rounded shadow">
                <h2 className="text-xl font-semibold mb-4">Integrations</h2>
                <p className="mb-4">Manage your connections to external tools and services.</p>
                <div className="grid grid-cols-2 gap-4">
                  {dashboardData.integrations.map((integration, index) => (
                    <div key={index} className="border p-4 rounded">
                      <h3 className="font-semibold mb-2">{integration.name}</h3>
                      <p className="text-sm text-gray-600 mb-2">Type: {integration.type}</p>
                      <div className={`text-sm ${integration.status === 'Connected' ? 'text-green-600' : 'text-red-600'} mb-2`}>
                        Status: {integration.status}
                      </div>
                      <button className={`px-4 py-2 rounded text-white ${integration.status === 'Connected' ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'}`}>
                        {integration.status === 'Connected' ? 'Disconnect' : 'Connect'}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          
          {activeSection === 'support' && (
            <div className="p-4 bg-white rounded shadow">
              <h2 className="text-xl font-semibold mb-4">Support</h2>
              <p className="mb-4">Access resources, training materials, and support for your compliance management system.</p>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-lg font-semibold mb-2">Documentation</h3>
                  <ul className="list-disc pl-5 space-y-2">
                    <li><a href="#" className="text-blue-600 hover:underline">User Guide</a></li>
                    <li><a href="#" className="text-blue-600 hover:underline">API Documentation</a></li>
                    <li><a href="#" className="text-blue-600 hover:underline">Best Practices</a></li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2">Training</h3>
                  <ul className="list-disc pl-5 space-y-2">
                    <li><a href="#" className="text-blue-600 hover:underline">Compliance Basics</a></li>
                    <li><a href="#" className="text-blue-600 hover:underline">Advanced Risk Management</a></li>
                    <li><a href="#" className="text-blue-600 hover:underline">AI Ethics and Governance</a></li>
                  </ul>
                </div>
              </div>
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">Contact Support</h3>
                <p className="mb-2">Need additional help? Our support team is available 24/7.</p>
                <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                  Contact Support
                </button>
              </div>
            </div>
          )}

          {activeSection === 'settings' && (
            <div className="p-4 bg-white rounded shadow">
              <h2 className="text-xl font-semibold mb-4">Settings</h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-lg font-semibold mb-2">User Preferences</h3>
                  <ul className="list-disc pl-5 space-y-2">
                    <li>Notification settings</li>
                    <li>Dashboard customization</li>
                    <li>Language preferences</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2">System Configuration</h3>
                  <ul className="list-disc pl-5 space-y-2">
                    <li>Integration settings</li>
                    <li>Data retention policies</li>
                    <li>AI model configuration</li>
                  </ul>
                </div>
              </div>
              <div className="mt-4">
                <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                  Save Changes
                </button>
              </div>
            </div>
          )}
        </main>
      </div>

      {/* Compliance Process Wizard Pop-up */}
      {showWizard && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
    <div className="bg-white rounded-lg w-full max-w-4xl max-h-screen overflow-auto">
      <div className="p-6">
        <div className="flex justify-end">
          <button onClick={handleWizardClose} className="text-gray-500 hover:text-gray-700">
            <XCircle size={24} />
          </button>
        </div>
        <ChakraWizardWrapper 
          onClose={handleWizardClose} 
          onSave={handleWizardSave}
          onDraftSaved={(draftProcess) => {
            setNewProcesses(prevProcesses => [...prevProcesses, {
              name: draftProcess.type,
              displayName: draftProcess.name,
              icon: FileIcon,
              category: 'newProcesses',
              status: 'draft'
            }]);
          }}
        />
      </div>
    </div>
  </div>
)}

      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-2xl w-full">
            <div className="p-6">
              <div className="flex justify-end">
                <button onClick={() => setModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                  <XCircle size={24} />
                </button>
              </div>
              {modalContent}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardWireframe;
