import React, { useState } from 'react';
import { FileText, Users, CheckSquare, ChevronDown, ChevronUp, AlertTriangle } from 'lucide-react';
import EUAIActOverview from './EUAIActOverview';
import RiskManagement from './RiskManagement';
import KeyRequirements from './KeyRequirements';
import TechnicalDocumentation from './TechnicalDocumentation';
import HumanOversight from './HumanOversight';
import ConformityAssessment from './ConformityAssessment';

const Section = ({ title, icon: Icon, content, isOpen, toggle }) => (
  <div className="mb-6 border rounded-lg overflow-hidden">
    <div
      className="flex items-center justify-between p-4 bg-gray-100 cursor-pointer"
      onClick={toggle}
    >
      <div className="flex items-center">
        <Icon className="w-6 h-6 text-blue-600 mr-3" />
        <h3 className="text-lg font-semibold">{title}</h3>
      </div>
      {isOpen ? <ChevronUp /> : <ChevronDown />}
    </div>
    {isOpen && (
      <div className="p-4 bg-white">
        {content}
      </div>
    )}
  </div>
);

const EUAIActComplianceHub = () => {
  const [openSections, setOpenSections] = useState({
    overview: true,
    riskManagement: false,
    keyRequirements: false,
    technicalDocumentation: false,
    humanOversight: false,
    conformityAssessment: false
  });

  const toggleSection = (section) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">EU AI Act Compliance Hub</h2>
      
      <Section
        title="Overview"
        icon={FileText}
        isOpen={openSections.overview}
        toggle={() => toggleSection('overview')}
        content={<EUAIActOverview />}
      />

      <Section
        title="Risk Management"
        icon={AlertTriangle}
        isOpen={openSections.riskManagement}
        toggle={() => toggleSection('riskManagement')}
        content={<RiskManagement />}
      />

      <Section
        title="Key Requirements"
        icon={CheckSquare}
        isOpen={openSections.keyRequirements}
        toggle={() => toggleSection('keyRequirements')}
        content={<KeyRequirements />}
      />

      <Section
        title="Technical Documentation"
        icon={FileText}
        isOpen={openSections.technicalDocumentation}
        toggle={() => toggleSection('technicalDocumentation')}
        content={<TechnicalDocumentation />}
      />

      <Section
        title="Human Oversight"
        icon={Users}
        isOpen={openSections.humanOversight}
        toggle={() => toggleSection('humanOversight')}
        content={<HumanOversight />}
      />

      <Section
        title="Conformity Assessment"
        icon={CheckSquare}
        isOpen={openSections.conformityAssessment}
        toggle={() => toggleSection('conformityAssessment')}
        content={<ConformityAssessment />}
      />
    </div>
  );
};

export default EUAIActComplianceHub;