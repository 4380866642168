import React, { useState } from 'react';
import { VStack, HStack, FormControl, FormLabel, SimpleGrid, Input, Button, Box, Collapse, useColorModeValue } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

const RiskCategories = ({ selectedRiskCategories, setSelectedRiskCategories, customRiskCategory, setCustomRiskCategory }) => {
  const [expandedCategory, setExpandedCategory] = useState(null);

  const handleCategoryClick = (category) => {
    setSelectedRiskCategories(prev => 
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const handleSubCategoryToggle = (category) => {
    setExpandedCategory(prev => (prev === category ? null : category));
  };

  const addCustomRiskCategory = () => {
    if (customRiskCategory && !selectedRiskCategories.includes(customRiskCategory)) {
      setSelectedRiskCategories([...selectedRiskCategories, customRiskCategory]);
      setCustomRiskCategory('');
    }
  };

  const riskClusters = {
    "Regulatory Compliance": ["GxP Compliance", "Data Integrity", "Labeling & Advertising", "Regulatory Submissions"],
    "Product Quality & Safety": ["Quality Control", "Pharmacovigilance", "Adverse Event Reporting", "Supply Chain Integrity"],
    "Anti-Bribery & Corruption": ["Anti-Bribery & Corruption (ABAC)", "Third-Party Risk Management (TPRM)", "Ethics & Compliance"],
    "HCP Engagement": ["HCP Payments & Transfers of Value", "HCP Contracting", "HCP Transparency Reporting"],
    "Privacy & Data Protection": ["GDPR Compliance", "HIPAA Compliance", "Data Breach Management", "Data Transfer Regulations"],
    "Environmental, Health & Safety (EHS)": ["EHS Compliance", "Chemical Safety", "Incident Reporting", "Occupational Health"],
    "Financial Compliance": ["SOX Compliance", "Financial Reporting", "Fraud Prevention", "Internal Controls"],
    "IT & Cybersecurity": ["Access Control", "Data Encryption", "Incident Response", "Cybersecurity Regulations"]
  };

  return (
    <VStack spacing={4} align="stretch">
      <FormControl>
        <FormLabel>Select Relevant Risk Categories:</FormLabel>
        <VStack spacing={3} align="stretch">
          {Object.entries(riskClusters).map(([cluster, categories]) => (
            <Box key={cluster} borderWidth={1} borderRadius="md" p={3} _hover={{ shadow: "md" }}>
              <Button
                onClick={() => handleSubCategoryToggle(cluster)}
                w="full"
                justifyContent="space-between"
                variant="outline"
                colorScheme={selectedRiskCategories.some(c => categories.includes(c)) ? "purple" : "gray"}
                size="sm"
              >
                {cluster}
                {expandedCategory === cluster ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Button>
              <Collapse in={expandedCategory === cluster} animateOpacity>
                <VStack mt={3} align="start" spacing={1}>
                  {categories.map(category => (
                    <Button
                      key={category}
                      onClick={() => handleCategoryClick(category)}
                      w="full"
                      justifyContent="flex-start"
                      variant={selectedRiskCategories.includes(category) ? "solid" : "ghost"}
                      colorScheme={selectedRiskCategories.includes(category) ? "purple" : "gray"}
                      size="xs"
                    >
                      {category}
                    </Button>
                  ))}
                </VStack>
              </Collapse>
            </Box>
          ))}
        </VStack>
      </FormControl>
      <HStack mt={4}>
        <Input
          placeholder="Add custom risk category"
          value={customRiskCategory}
          onChange={(e) => setCustomRiskCategory(e.target.value)}
          size="sm"
        />
        <Button onClick={addCustomRiskCategory} colorScheme="purple" size="sm">Add</Button>
      </HStack>
    </VStack>
  );
};

export default RiskCategories;
