import React from 'react';
import Button from './Button';

const CallToAction = () => {
  const typeformUrl = "https://adi18k4liww.typeform.com/to/DYf77usz";

  return (
    <div className="text-center my-12 mb-24">
      <h3 className="text-3xl font-bold text-center mb-6 text-gradient">Ready to Revolutionize Your Compliance Management?</h3>
      <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
        Join forward-thinking healthcare and pharmaceutical companies using LOKATIAL to transform compliance into a strategic advantage.
      </p>
      <a href={typeformUrl} target="_blank" rel="noopener noreferrer">
        <Button className="btn btn-primary hover-scale text-xl py-3 px-8">
          Get Early Access
        </Button>
      </a>
    </div>
  );
};

export default CallToAction;