import React, { memo } from 'react';
import { ChakraProvider, extendTheme, Box } from '@chakra-ui/react';
import ComplianceProcessWizard from './ComplianceProcessWizard';

// Define the theme
const theme = extendTheme({
  colors: {
    purple: {
      50: "#FAF5FF",
      100: "#E9D8FD",
      200: "#D6BCFA",
      300: "#B794F4",
      400: "#9F7AEA",
      500: "#805AD5",
      600: "#6B46C1",
      700: "#553C9A",
      800: "#44337A",
      900: "#322659",
    },
    blue: {
      50: "#EBF8FF",
      100: "#BEE3F8",
      500: "#3182CE",
      600: "#2B6CB0",
    },
    gray: {
      50: "#F7FAFC",
      800: "#1A202C",
    },
    // Add any other colors here
  },
  fonts: {
    body: "system-ui, sans-serif",
    heading: "Georgia, serif",
    mono: "Menlo, monospace",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  // You can add other theme customizations here as needed
});

const ChakraWizardWrapper = memo(({ onClose, onSave, onDraftSaved, ...otherProps }) => {
  return (
    <ChakraProvider theme={theme}>
      <Box
        as="style"
        dangerouslySetInnerHTML={{
          __html: `
            body, button, input, textarea, select {
              font-family: ${theme.fonts.body};
            }
            h1, h2, h3, h4, h5, h6 {
              font-family: ${theme.fonts.heading};
            }
          `
        }}
      />
      <ComplianceProcessWizard 
        onClose={onClose}
        onSave={onSave}
        onDraftSaved={onDraftSaved}
        {...otherProps}
      />
    </ChakraProvider>
  );
});

export default ChakraWizardWrapper;