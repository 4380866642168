import React, { useState, useEffect } from 'react';
import { ShieldCheck, AlertTriangle, FileText, Eye, Database, UserCheck, Lock, CheckSquare } from 'lucide-react';

const EUAIActOverview = () => {
  const [actionItems, setActionItems] = useState([
    { id: 1, text: "Conduct an organization-wide AI inventory and risk assessment", completed: false },
    { id: 2, text: "Establish a cross-functional AI governance committee", completed: false },
    { id: 3, text: "Develop a compliance roadmap with clear milestones and responsibilities", completed: false },
    { id: 4, text: "Invest in AI literacy training for staff at all levels", completed: false },
    { id: 5, text: "Engage with regulatory bodies and industry peers for best practice sharing", completed: false },
  ]);

  useEffect(() => {
    const savedItems = localStorage.getItem('euAiActActionItems');
    if (savedItems) {
      setActionItems(JSON.parse(savedItems));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('euAiActActionItems', JSON.stringify(actionItems));
  }, [actionItems]);

  const toggleItem = (id) => {
    setActionItems(actionItems.map(item => 
      item.id === id ? { ...item, completed: !item.completed } : item
    ));
  };

  const impactAreas = [
    { icon: ShieldCheck, title: "Clinical Decision Support", description: "AI systems for diagnosis and treatment planning will require rigorous validation and continuous monitoring." },
    { icon: AlertTriangle, title: "Drug Discovery", description: "AI models used in drug discovery must adhere to strict data governance and transparency standards." },
    { icon: Database, title: "Patient Data Management", description: "Enhanced data protection measures and explicit consent mechanisms for AI-driven patient data analysis." },
    { icon: UserCheck, title: "Human Oversight", description: "Mandatory human supervision for AI systems used in critical healthcare decisions." },
  ];

  const complianceSteps = [
    { icon: FileText, title: "Risk Assessment", description: "Conduct thorough risk assessments for all AI systems used in healthcare settings." },
    { icon: Eye, title: "Transparency", description: "Ensure clear documentation and explainability of AI decision-making processes." },
    { icon: Lock, title: "Data Governance", description: "Implement robust data management practices to ensure data quality and privacy." },
    { icon: ShieldCheck, title: "Ongoing Monitoring", description: "Establish continuous monitoring systems to track AI performance and safety." },
  ];

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-blue-800">EU AI Act: Healthcare & Pharma Impact</h2>
      <p className="mb-6 text-lg leading-relaxed text-gray-700">
        The EU AI Act introduces comprehensive regulations for AI systems, with significant implications for healthcare and pharmaceutical industries. This framework aims to ensure patient safety, data privacy, and ethical use of AI in medical applications.
      </p>

      <h3 className="text-2xl font-semibold mb-4 text-blue-700">Key Impact Areas</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        {impactAreas.map((area, index) => (
          <div key={index} className="flex items-start p-4 bg-blue-50 rounded-lg">
            <area.icon className="w-8 h-8 text-blue-600 mr-4 flex-shrink-0" />
            <div>
              <h4 className="text-lg font-semibold mb-2">{area.title}</h4>
              <p className="text-gray-600">{area.description}</p>
            </div>
          </div>
        ))}
      </div>

      <h3 className="text-2xl font-semibold mb-4 text-blue-700">Compliance Roadmap</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        {complianceSteps.map((step, index) => (
          <div key={index} className="flex items-start p-4 bg-green-50 rounded-lg">
            <step.icon className="w-8 h-8 text-green-600 mr-4 flex-shrink-0" />
            <div>
              <h4 className="text-lg font-semibold mb-2">{step.title}</h4>
              <p className="text-gray-600">{step.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8 p-4 bg-yellow-50 rounded-lg border border-yellow-200">
        <h4 className="text-lg font-semibold mb-4 text-yellow-800">Executive Action Items</h4>
        <div className="space-y-2">
          {actionItems.map((item) => (
            <div key={item.id} className="flex items-center">
              <button 
                onClick={() => toggleItem(item.id)}
                className={`flex-shrink-0 w-6 h-6 mr-2 border-2 rounded ${
                  item.completed 
                    ? 'bg-green-500 border-green-500 text-white' 
                    : 'border-gray-400'
                }`}
              >
                {item.completed && <CheckSquare className="w-5 h-5" />}
              </button>
              <span className={`flex-grow ${item.completed ? 'line-through text-gray-500' : 'text-gray-700'}`}>
                {item.text}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4 text-sm text-gray-500">
        Progress is automatically saved. Your checklist will be remembered on your next visit.
      </div>
    </div>
  );
};

export default EUAIActOverview;