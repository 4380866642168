import React from 'react';
import AIAnalyzeButton from './AIAnalyzeButton';
import { Upload } from 'lucide-react';

const WorkflowList = ({ workflows, onUpload, onAnalyze }) => (
  <div className="bg-white p-4 rounded shadow">
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-lg font-semibold">Workflow Visuals</h3>
      <button 
        onClick={() => onUpload('workflow')} 
        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 flex items-center"
      >
        <Upload size={16} className="mr-2" />
        Upload Workflow
      </button>
    </div>
    {workflows.length > 0 ? (
      <ul className="space-y-2">
        {workflows.map((workflow) => (
          <li key={workflow.id} className="flex justify-between items-center">
            <span>{workflow.name}</span>
            <div className="flex items-center">
              <span className="text-sm text-gray-500 mr-4">Last updated: {workflow.lastUpdated}</span>
              <AIAnalyzeButton onClick={onAnalyze} item={workflow} />
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <p className="text-gray-500">No workflows available. Upload a workflow to get started.</p>
    )}
  </div>
);

export default WorkflowList;