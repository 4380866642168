import React, { useState } from 'react';
import { FileText, GitBranch, Plus, Upload, List } from 'lucide-react';

const TechnicalDocumentation = () => {
  const [documents, setDocuments] = useState([
    { id: 1, name: 'AI System Architecture', version: '1.2', lastUpdated: '2023-07-15' },
    { id: 2, name: 'Data Management Protocol', version: '2.0', lastUpdated: '2023-08-01' },
    { id: 3, name: 'Model Training Methodology', version: '1.5', lastUpdated: '2023-07-22' },
  ]);

  return (
    <div className="p-4 bg-white rounded shadow">
      <h2 className="text-xl font-semibold mb-4">Technical Documentation</h2>
      <p className="mb-4 text-gray-600">
        Comprehensive documentation of AI systems as required by the EU AI Act, including system architecture, 
        data specifications, and performance metrics. This ensures transparency and facilitates compliance assessment.
      </p>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h3 className="text-lg font-medium mb-2">Document Management</h3>
          <div className="space-y-2">
            <button className="flex items-center text-blue-600 hover:text-blue-800">
              <Plus size={16} className="mr-1" /> Create New Document
            </button>
            <button className="flex items-center text-blue-600 hover:text-blue-800">
              <Upload size={16} className="mr-1" /> Upload Document
            </button>
            <button className="flex items-center text-blue-600 hover:text-blue-800">
              <List size={16} className="mr-1" /> View All Documents
            </button>
          </div>
          <div className="mt-4">
            <h4 className="font-medium mb-2">Recent Documents:</h4>
            <ul className="space-y-2">
              {documents.map(doc => (
                <li key={doc.id} className="flex items-center justify-between">
                  <span className="flex items-center">
                    <FileText size={16} className="mr-2 text-gray-500" />
                    {doc.name}
                  </span>
                  <span className="text-sm text-gray-500">v{doc.version}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-lg font-medium mb-2">Version Control</h3>
          <div className="space-y-2">
            <button className="flex items-center text-blue-600 hover:text-blue-800">
              <GitBranch size={16} className="mr-1" /> View Version History
            </button>
          </div>
          <div className="mt-4">
            <h4 className="font-medium mb-2">Recent Updates:</h4>
            <ul className="space-y-2">
              {documents.map(doc => (
                <li key={doc.id} className="text-sm">
                  <span className="font-medium">{doc.name}</span> updated to v{doc.version} on {doc.lastUpdated}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalDocumentation;