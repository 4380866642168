import React, { useState } from 'react';
import { Box, Text, Tag, TagLabel, Input, VStack, HStack, Spinner } from "@chakra-ui/react";
import { PlusCircle } from 'lucide-react';

const DepartmentDisplay = ({ 
  involvedDepartments, 
  setInvolvedDepartments
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [customDepartment, setCustomDepartment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const defaultDepartments = ["Legal", "Quality Assurance", "Regulatory Affairs", "Research & Development", "Manufacturing", "Compliance"];

  const toggleDepartment = (dept) => {
    setIsLoading(true);
    setTimeout(() => {
      setInvolvedDepartments(prev => 
        prev.includes(dept) ? prev.filter(d => d !== dept) : [...prev, dept]
      );
      setIsLoading(false);
    }, 500);
  };

  const addCustomDepartment = (e) => {
    if (e.key === 'Enter' && customDepartment && !involvedDepartments.includes(customDepartment)) {
      setIsLoading(true);
      setTimeout(() => {
        setInvolvedDepartments([...involvedDepartments, customDepartment]);
        setCustomDepartment('');
        setIsAdding(false);
        setIsLoading(false);
      }, 500);
    }
  };

  return (
    <VStack align="stretch" spacing={4}>
      <Text fontWeight="bold">Involved Departments:</Text>
      <HStack flexWrap="wrap" spacing={2}>
        {defaultDepartments.map((dept, index) => (
          <Tag 
            key={index} 
            size="lg"
            borderRadius="full"
            variant={involvedDepartments.includes(dept) ? "solid" : "outline"}
            colorScheme="purple"
            cursor="pointer"
            onClick={() => toggleDepartment(dept)}
          >
            <TagLabel>{dept}</TagLabel>
          </Tag>
        ))}
        {involvedDepartments.filter(dept => !defaultDepartments.includes(dept)).map((dept, index) => (
          <Tag 
            key={`custom-${index}`} 
            size="lg"
            borderRadius="full"
            variant="solid"
            colorScheme="blue"
            cursor="pointer"
            onClick={() => toggleDepartment(dept)}
          >
            <TagLabel>{dept}</TagLabel>
          </Tag>
        ))}
        {isAdding ? (
          <Input
            size="sm"
            placeholder="Type and press Enter"
            value={customDepartment}
            onChange={(e) => setCustomDepartment(e.target.value)}
            onKeyPress={addCustomDepartment}
            onBlur={() => setIsAdding(false)}
            autoFocus
          />
        ) : (
          <Tag
            size="lg"
            borderRadius="full"
            variant="outline"
            colorScheme="green"
            cursor="pointer"
            onClick={() => setIsAdding(true)}
          >
            <TagLabel>+ Add Custom</TagLabel>
          </Tag>
        )}
      </HStack>
      {isLoading && (
        <HStack>
          <Spinner size="sm" />
          <Text fontSize="sm">Updating process design...</Text>
        </HStack>
      )}
    </VStack>
  );
};

export default DepartmentDisplay;