import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Shield, TrendingUp, DollarSign, Zap, Globe, ArrowRight, Heart, Menu, X, Cpu, Lock, Microscope, PlusCircle, Brain, FileText, Clock, UserCheck, Target, Award, Users, ShieldCheck, Sparkles, Beaker, ChevronDown, ChevronUp, FileSearch } from 'lucide-react';
import Button from './Button';
import ValueProposition from './ValueProposition';
import WizardProcessFeature from './WizardProcessFeature';
import AIGovernanceSection from './AIGovernanceSection';
import UseCases from './UseCases';
import CallToAction from './CallToAction';
import ComplianceFrameworksSection from './ComplianceFrameworksSection';
import IntegrationEcosystemSection from './IntegrationEcosystemSection';

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const typeformUrl = "https://adi18k4liww.typeform.com/to/DYf77usz";
  const wizardProcessFeatureRef = useRef(null);
  const useCasesSectionRef = useRef(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setIsMenuOpen(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <header className="bg-white shadow-sm sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-blue-600">LOKATIAL</h1>
          
          {/* Desktop Menu */}
          <nav className="hidden md:flex space-x-4 items-center">
            <Button 
              variant="ghost" 
              className="text-black hover:text-gray-700" 
              onClick={() => scrollToSection(wizardProcessFeatureRef)}
            >
              Features
            </Button>
            <Button 
              variant="ghost" 
              className="text-black hover:text-gray-700" 
              onClick={() => scrollToSection(useCasesSectionRef)}
            >
              Use Cases
            </Button>
            <a href={typeformUrl} target="_blank" rel="noopener noreferrer">
              <Button variant="ghost" className="text-black hover:text-gray-700">Contact</Button>
            </a>
          </nav>
  
          {/* Mobile Burger Menu Icon */}
          <button className="md:hidden" onClick={toggleMenu}>
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
  
        {/* Mobile Menu */}
        <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'} absolute top-full left-0 right-0 bg-white shadow-md z-50`}>
          <div className="px-4 py-2 space-y-2">
            <Button 
              variant="ghost" 
              className="text-black hover:text-gray-700 w-full text-left" 
              onClick={() => {
                scrollToSection(wizardProcessFeatureRef);
                toggleMenu();
              }}
            >
              Features
            </Button>
            <Button 
              variant="ghost" 
              className="text-black hover:text-gray-700 w-full text-left" 
              onClick={() => {
                scrollToSection(useCasesSectionRef);
                toggleMenu();
              }}
            >
              Use Cases
            </Button>
            <a href={typeformUrl} target="_blank" rel="noopener noreferrer" className="block w-full" onClick={toggleMenu}>
              <Button variant="ghost" className="text-black hover:text-gray-700 w-full text-left">Contact</Button>
            </a>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-16">
        <section className="text-center mb-12 animate-fade-in">
          <h1 className="text-5xl font-bold mb-6 text-gradient leading-relaxed">
            Transform Compliance into a Strategic Advantage
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
            LOKATIAL revolutionizes compliance management for healthcare and pharma companies with AI-powered process design and real-time adaptation to regulatory changes.
          </p>
        </section>

        <ValueProposition />
        
        <WizardProcessFeature ref={wizardProcessFeatureRef} />

        <AIGovernanceSection />

        <CallToAction />
        
        <UseCases ref={useCasesSectionRef} />

        <ComplianceFrameworksSection />

        <IntegrationEcosystemSection />

        <CallToAction />
      </main>

     <footer className="bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="text-center sm:text-left">
              <h4 className="text-lg font-semibold mb-4">About LOKATIAL</h4>
              <p className="text-gray-400">Pioneering AI-driven compliance and AI management solutions for forward-thinking enterprises.</p>
            </div>
            <div className="text-center sm:text-left">
              <h4 className="text-lg font-semibold mb-4">Our Solutions</h4>
              <ul className="space-y-2 text-gray-400">
                <li>
                  <strong>CMS (Compliance Management System):</strong> AI-powered platform for streamlined compliance processes and risk management.
                </li>
                <li>
                  <strong>AIMS (AI Management System):</strong> Comprehensive solution for ethical AI governance and model lifecycle management.
                </li>
              </ul>
            </div>
            <div className="text-center sm:text-left">
              <h4 className="text-lg font-semibold mb-4">Connect With Us</h4>
              <p className="text-gray-400 mb-4">Stay updated on the latest in AI-powered compliance and AI management.</p>
              <a 
                href={typeformUrl} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-full inline-flex items-center justify-center transition duration-300 w-full sm:w-auto"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
            <p className="mb-2">&copy; 2024 LOKATIAL. All rights reserved.</p>
            <p className="flex items-center justify-center">
              Made with <Heart className="mx-1 text-red-500" size={16} /> in Germany
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;