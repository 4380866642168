import React, { useState } from 'react';
import { VStack, Heading, Text, SimpleGrid, Box, useColorModeValue } from '@chakra-ui/react';
import { Shield, ClipboardList, Clock, FileText } from 'lucide-react';

const ProcessSelectionStep = ({ onSelectProcess }) => {
  const [selectedProcess, setSelectedProcess] = useState(null);

  const processes = [
    { name: 'Risk Assessment', icon: Shield, description: 'Identify and evaluate potential risks in your organization', enabled: true },
    { name: 'Compliance Monitoring', icon: ClipboardList, description: 'Monitor and track compliance with regulations and internal policies', enabled: true },
    { name: 'Incident Management', icon: Clock, description: 'Respond to and manage compliance incidents effectively', enabled: false },
    { name: 'Policy Management', icon: FileText, description: 'Create, review, and maintain compliance policies and procedures', enabled: false },
  ];

  const headingColor = useColorModeValue("purple.700", "purple.200");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const buttonBg = useColorModeValue("white", "gray.700");
  const buttonBorder = useColorModeValue("purple.200", "purple.500");
  const selectedBg = useColorModeValue("purple.100", "purple.600");

  const handleProcessClick = (process) => {
    if (process.enabled) {
      setSelectedProcess(process.name);
      onSelectProcess(process.name);
    }
  };

  return (
    <VStack spacing={8} align="stretch">
      <Heading size="lg" color={headingColor}>Select a Compliance Process to Design</Heading>
      <Text fontSize="md" color={textColor}>
        Choose the type of process you want to create using our AI-powered wizard:
      </Text>
      <SimpleGrid columns={[1, null, 2]} spacing={6}>
        {processes.map((process) => (
          <Box
            key={process.name}
            p={6}
            bg={selectedProcess === process.name ? selectedBg : buttonBg}
            borderWidth={2}
            borderColor={selectedProcess === process.name ? "purple.500" : buttonBorder}
            borderRadius="lg"
            cursor={process.enabled ? "pointer" : "not-allowed"}
            onClick={() => handleProcessClick(process)}
            opacity={process.enabled ? 1 : 0.5}
            transition="all 0.2s"
            _hover={process.enabled ? { transform: "translateY(-2px)", boxShadow: "md" } : {}}
          >
            <VStack align="start" spacing={3}>
              <Box color={process.enabled ? "purple.500" : "gray.400"}>
                <process.icon size={24} />
              </Box>
              <Text fontWeight="bold" color={process.enabled ? headingColor : "gray.400"}>{process.name}</Text>
              <Text fontSize="sm" color={process.enabled ? textColor : "gray.400"}>{process.description}</Text>
              {!process.enabled && (
                <Text fontSize="xs" fontStyle="italic" color="gray.500">Coming soon</Text>
              )}
            </VStack>
          </Box>
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default ProcessSelectionStep;