import React, { useState, useEffect, useRef } from 'react';
import { Brain, Send, Loader, X, Paperclip } from 'lucide-react';
import { Button } from './ui/Button';

const riskFactors = [
  {
    title: "EU-US Data Privacy Framework",
    description: "New framework replacing Privacy Shield, affecting transatlantic data flows.",
    date: "July 2023",
    recommendation: "Review and update data transfer mechanisms for EU citizen data. Consider applying for certification under the new framework."
  },
  {
    title: "AI Act in EU",
    description: "Introduces strict rules for AI in healthcare, impacting data usage and model training.",
    date: "Expected adoption in early 2024",
    recommendation: "Leverage our AIMS (AI Management System) platform to conduct comprehensive AI impact assessments for all healthcare-related AI systems. Use AIMS to develop and implement a robust governance framework for AI data usage in clinical and research contexts, ensuring compliance with the upcoming EU AI Act regulations."  
  },
  {
    title: "China's Data Security Law",
    description: "Imposes strict requirements on data collection, processing, and cross-border transfers.",
    date: "Implemented September 2021",
    recommendation: "Review all data practices related to Chinese operations. Implement country-specific data transfer agreements and consider data localization strategies."
  },
  {
    title: "GDPR Enforcement Actions",
    description: "Increased fines for healthcare data processors, with a focus on AI and machine learning data usage.",
    date: "Ongoing trend in 2023",
    recommendation: "Conduct a fresh Data Protection Impact Assessment (DPIA) for all data processing activities. Review and update consent mechanisms, especially for AI and ML data usage."
  },
  {
    title: "US State Privacy Laws",
    description: "New comprehensive privacy laws in California (CPRA), Virginia, Colorado, and Connecticut.",
    date: "Various implementation dates in 2023",
    recommendation: "Update privacy policies and data handling practices to comply with new state laws. Implement data subject request handling mechanisms as required by these laws."
  },
  {
    title: "Proposed HIPAA Updates",
    description: "Expanding the scope of Protected Health Information and new rules for health data interoperability.",
    date: "Expected 2024",
    recommendation: "Prepare systems for enhanced interoperability requirements. Review and expand the categorization of Protected Health Information in anticipation of new rules."
  }
];

const LokCO = ({ message, onClose, initiateRiskAnalysis }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([
    { text: "Hello! I'm LokCO, your Compliance Co-pilot. How can I assist you today? I can help with risk assessments, policy reviews, or answer any compliance-related questions.", sender: 'LokCO' }
  ]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [currentRiskIndex, setCurrentRiskIndex] = useState(-1);
  const [analysisComplete, setAnalysisComplete] = useState(false);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (initiateRiskAnalysis) {
      beginRiskAnalysis();
    }
  }, [initiateRiskAnalysis]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const beginRiskAnalysis = () => {
    setMessages(prev => [...prev, {
      text: "I've prepared a detailed analysis of emerging data privacy risks. Would you like to go through them?",
      sender: 'LokCO'
    }]);
  };

  const handleSend = () => {
    if (input.trim()) {
      sendMessage(input);
    }
  };

  const sendMessage = (text) => {
    setMessages(prev => [...prev, { text, sender: 'User' }]);
    setInput('');
    setIsAnalyzing(true);
    
    setTimeout(() => {
      setIsAnalyzing(false);
      processUserInput(text);
    }, 2000); // Simulating a short delay
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const processUserInput = (userInput) => {
    const lowerInput = userInput.toLowerCase();
    
    setIsAnalyzing(true);
    setTimeout(() => {
      setIsAnalyzing(false);
      
      if (currentRiskIndex === -1) {
        if (lowerInput.includes('yes')) {
          startRiskAnalysis();
        } else {
          setMessages(prev => [...prev, {
            text: "Alright. If you'd like to go through the risk analysis later, just let me know. Is there anything else I can help you with?",
            sender: 'LokCO'
          }]);
        }
      } else if (currentRiskIndex < riskFactors.length) {
        if (lowerInput.includes('yes') || lowerInput.includes('relevant')) {
          provideRecommendation();
        } else {
          moveToNextRisk();
        }
      } else if (analysisComplete) {
        handlePostAnalysisQuery(lowerInput);
      } else {
        generalConversation(lowerInput);
      }
    }, 2000);
  };

  const startRiskAnalysis = () => {
    setCurrentRiskIndex(0);
    presentRiskFactor(0);
  };

  const presentRiskFactor = (index) => {
    const risk = riskFactors[index];
    setMessages(prev => [...prev, {
      text: `Risk Factor ${index + 1}: ${risk.title} (${risk.date})\n\n${risk.description}\n\nIs this relevant to your organization?`,
      sender: 'LokCO'
    }]);
  };

  const provideRecommendation = () => {
    const risk = riskFactors[currentRiskIndex];
    setMessages(prev => [...prev, {
      text: `Recommendation: ${risk.recommendation}\n\nShall we move on to the next risk factor?`,
      sender: 'LokCO'
    }]);
  };

  const moveToNextRisk = () => {
    const nextIndex = currentRiskIndex + 1;
    if (nextIndex < riskFactors.length) {
      setCurrentRiskIndex(nextIndex);
      presentRiskFactor(nextIndex);
    } else {
      completeAnalysis();
    }
  };

  const completeAnalysis = () => {
    setAnalysisComplete(true);
    setMessages(prev => [...prev, {
      text: "We've covered all the main risk factors. Would you like a summary of the recommended actions or do you have any specific questions?",
      sender: 'LokCO'
    }]);
  };

  const handlePostAnalysisQuery = (query) => {
    if (query.includes('summary')) {
      provideSummary();
    } else {
      setMessages(prev => [...prev, {
        text: "I'm here to help with any specific questions you might have about the risks we discussed or any other compliance concerns. What would you like to know more about?",
        sender: 'LokCO'
      }]);
    }
  };

  const provideSummary = () => {
    const summary = riskFactors.map(risk => `- ${risk.title}: ${risk.recommendation}`).join('\n');
    setMessages(prev => [...prev, {
      text: `Here's a summary of recommended actions:\n\n${summary}\n\nIs there any specific area you'd like more information on?`,
      sender: 'LokCO'
    }]);
  };

  const generalConversation = (input) => {
    if (input.includes('risk') || input.includes('compliance')) {
      setMessages(prev => [...prev, {
        text: "I can help you with various aspects of risk management and compliance. Would you like to go through our risk analysis, discuss a specific compliance area, or learn about best practices?",
        sender: 'LokCO'
      }]);
    } else if (input.includes('policy') || input.includes('procedure')) {
      setMessages(prev => [...prev, {
        text: "I can assist with policy reviews and updates. Which specific policy or procedure would you like to discuss?",
        sender: 'LokCO'
      }]);
    } else {
      setMessages(prev => [...prev, {
        text: "I'm here to help with any compliance-related questions or concerns. Could you please provide more context about what you'd like assistance with?",
        sender: 'LokCO'
      }]);
    }
  };

  return (
    <div className={`fixed ${isOpen ? 'bottom-4 right-4 w-[350px] h-[450px]' : 'bottom-4 right-4 w-16 h-16'} bg-white rounded-lg shadow-2xl border border-blue-200 z-50 transition-all duration-300 ease-in-out overflow-hidden`}>
      {isOpen ? (
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center p-4 bg-gradient-to-r from-blue-600 to-blue-800 text-white">
            <div className="flex items-center">
              <Brain className="w-6 h-6 mr-2" />
              <h4 className="text-lg font-semibold">LokCO</h4>
            </div>
            <button onClick={() => setIsOpen(false)} className="text-white hover:text-gray-200 transition-colors duration-200">
              <X className="w-5 h-5" />
            </button>
          </div>
          <div ref={chatContainerRef} className="flex-1 overflow-auto p-4 space-y-4 bg-gray-50">
            {messages.map((msg, index) => (
              <div key={index} className={`flex ${msg.sender === 'User' ? 'justify-end' : 'justify-start'} animate-fade-in-up`}>
                <div className={`max-w-[75%] p-3 rounded-lg shadow-md ${msg.sender === 'User' ? 'bg-blue-500 text-white' : 'bg-white'}`}>
                  <p className="text-sm">{msg.text}</p>
                </div>
              </div>
            ))}
            {isAnalyzing && (
              <div className="flex items-center justify-center animate-pulse">
                <Loader className="w-5 h-5 text-blue-500 animate-spin" />
                <span className="ml-2 text-sm text-gray-600">LokCO is pondering...</span>
              </div>
            )}
          </div>
          <div className="p-4 bg-white border-t flex items-center">
            <textarea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Ask LokCO..."
              className="flex-1 border rounded-l-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none text-sm"
              rows="2"
            />
            <Button onClick={() => fileInputRef.current.click()} className="px-3 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 transition-colors duration-200">
              <Paperclip className="w-5 h-5" />
            </Button>
            <input 
              type="file"
              ref={fileInputRef}
              onChange={(e) => {/* Handle file upload */}}
              className="hidden"
            />
            <Button onClick={handleSend} className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-r-md transition-colors duration-200">
              <Send className="w-5 h-5" />
            </Button>
          </div>
        </div>
      ) : (
        <Button onClick={() => setIsOpen(true)} className="w-full h-full rounded-full p-0 bg-blue-600 hover:bg-blue-700 transition-colors duration-200">
          <Brain className="w-8 h-8 text-white" />
        </Button>
      )}
    </div>
  );
};

export default LokCO;