import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const EnhancedAIOverview = ({ 
  dashboardData, 
  EUAIActComplianceTracker,
  NISTAIFrameworkOverview,
  ScoreCard,
  AIModelList,
  AIIncidentManagement
}) => {

  if (!dashboardData) {
    return <div>Loading dashboard data...</div>;
  }

  if (dashboardData.error) {
    return <div>Error loading dashboard data: {dashboardData.error}</div>;
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6 mb-6">
        <EUAIActComplianceTracker />
        <NISTAIFrameworkOverview />
      </div>

      <div className="grid grid-cols-3 gap-6 mb-6">
        <ScoreCard title="Overall AI Compliance Score" score={dashboardData.overallAIScore} color="blue" />
        <ScoreCard title="Ethical AI Score" score={dashboardData.ethicalAIScore} color="green" />
        <ScoreCard title="AI Observability Score" score={dashboardData.aiObservabilityScore} color="yellow" />
      </div>

      <div className="grid grid-cols-2 gap-6">
        <AIModelList models={dashboardData.aiModels} />
        <AIIncidentManagement incidents={dashboardData.aiIncidents} />
      </div>
    </div>
  );
};

EnhancedAIOverview.propTypes = {
  dashboardData: PropTypes.shape({
    overallAIScore: PropTypes.number.isRequired,
    ethicalAIScore: PropTypes.number.isRequired,
    aiObservabilityScore: PropTypes.number.isRequired,
    aiModels: PropTypes.array.isRequired,
    aiIncidents: PropTypes.array.isRequired,
  }).isRequired,
  AIRiskManagementOverview: PropTypes.elementType.isRequired,
  EUAIActComplianceTracker: PropTypes.elementType.isRequired,
  NISTAIFrameworkOverview: PropTypes.elementType.isRequired,
  ScoreCard: PropTypes.elementType.isRequired,
  AIModelList: PropTypes.elementType.isRequired,
  AIIncidentManagement: PropTypes.elementType.isRequired,
};

export default EnhancedAIOverview;