import React, { useState } from 'react';
import { AlertTriangle, Shield, CheckCircle, XCircle, AlertCircle, Activity, Sliders, ChevronDown, ChevronUp } from 'lucide-react';

const RiskManagement = () => {
  const [selectedRisk, setSelectedRisk] = useState(null);
  const [expandedStep, setExpandedStep] = useState(null);

  const riskCategories = [
    { id: 1, name: 'Patient Safety', icon: AlertTriangle, color: 'text-red-500' },
    { id: 2, name: 'Data Privacy', icon: Shield, color: 'text-blue-500' },
    { id: 3, name: 'Model Bias', icon: Sliders, color: 'text-yellow-500' },
    { id: 4, name: 'Regulatory Compliance', icon: CheckCircle, color: 'text-green-500' },
    { id: 5, name: 'Operational Risks', icon: Activity, color: 'text-purple-500' },
  ];

  const riskMatrix = [
    { id: 1, level: 'High', icon: XCircle, color: 'bg-red-100 text-red-700' },
    { id: 2, level: 'Medium', icon: AlertCircle, color: 'bg-yellow-100 text-yellow-700' },
    { id: 3, level: 'Low', icon: CheckCircle, color: 'bg-green-100 text-green-700' },
  ];

  const riskMitigationSteps = [
    {
      step: "Identify AI systems and their use cases",
      details: "Common healthcare AI use cases include:",
      examples: [
        "Clinical decision support systems for diagnosis",
        "AI-powered medical imaging analysis",
        "Predictive analytics for patient outcomes",
        "Drug discovery and development AI models",
        "AI-driven personalized treatment planning"
      ],
      articles: ["Article 9: Risk management system", "Article 61: Post-market monitoring"]
    },
    {
      step: "Assess each system against EU AI Act criteria",
      details: "Evaluate systems based on:",
      examples: [
        "Intended purpose and potential impact on patient safety",
        "Level of autonomy in decision-making",
        "Transparency and explainability of the AI system",
        "Data used for training and operation"
      ],
      articles: ["Article 6: Classification rules for high-risk AI systems", "Annex III: High-risk AI systems"]
    },
    {
      step: "Classify systems (high-risk, limited risk, minimal risk)",
      details: "Examples of classifications:",
      examples: [
        "High-risk: AI for cancer diagnosis or treatment planning",
        "Limited risk: AI chatbots for patient inquiries",
        "Minimal risk: AI for hospital resource management"
      ],
      articles: ["Article 6: Classification rules for high-risk AI systems", "Article 52: Transparency obligations"]
    },
    {
      step: "Implement required controls based on classification",
      details: "Key controls include:",
      examples: [
        "Rigorous testing and validation for high-risk systems",
        "Implementing human oversight mechanisms",
        "Ensuring data quality and governance",
        "Establishing clear audit trails"
      ],
      articles: ["Articles 8-15: Requirements for high-risk AI systems", "Article 14: Human oversight"]
    },
    {
      step: "Establish ongoing monitoring and reporting processes",
      details: "Continuous monitoring should include:",
      examples: [
        "Regular performance assessments",
        "Tracking of adverse events or near-misses",
        "Monitoring for model drift or data quality issues",
        "Periodic re-validation of high-risk systems"
      ],
      articles: ["Article 61: Post-market monitoring", "Article 62: Reporting of serious incidents and malfunctions"]
    },
    {
      step: "Conduct regular audits and updates",
      details: "Audit and update processes should cover:",
      examples: [
        "Compliance with evolving regulatory requirements",
        "Assessment of system performance and safety",
        "Review of data protection and privacy measures",
        "Evaluation of the effectiveness of risk mitigation strategies"
      ],
      articles: ["Article 43: Conformity assessment", "Article 65: Market surveillance and control"]
    },
  ];

  const toggleStep = (index) => {
    setExpandedStep(expandedStep === index ? null : index);
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6 text-blue-800">AI Risk Management Framework</h2>
      
      <p className="mb-6 text-lg leading-relaxed text-gray-700">
        Under the EU AI Act, healthcare and pharma organizations must implement a robust risk management system for AI applications. This framework helps identify, assess, and mitigate risks associated with AI systems, as mandated by Article 9 of the EU AI Act.
      </p>

      <h3 className="text-2xl font-semibold mb-4 text-blue-700">Key Risk Categories</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        {riskCategories.map((category) => (
          <button
            key={category.id}
            className={`p-4 rounded-lg border-2 transition-all ${
              selectedRisk === category.id ? 'border-blue-500 bg-blue-50' : 'border-gray-200 hover:border-blue-300'
            }`}
            onClick={() => setSelectedRisk(category.id)}
          >
            <category.icon className={`w-8 h-8 mb-2 ${category.color}`} />
            <h4 className="font-semibold">{category.name}</h4>
          </button>
        ))}
      </div>

      <h3 className="text-2xl font-semibold mb-4 text-blue-700">EU AI Act: Risk Mitigation Steps</h3>
      <div className="space-y-4 mb-8">
        {riskMitigationSteps.map((step, index) => (
          <div key={index} className="border border-gray-200 rounded-lg">
            <button
              className="w-full px-4 py-2 flex justify-between items-center bg-gray-50 hover:bg-gray-100"
              onClick={() => toggleStep(index)}
            >
              <span className="font-semibold text-left">{step.step}</span>
              {expandedStep === index ? <ChevronUp /> : <ChevronDown />}
            </button>
            {expandedStep === index && (
              <div className="p-4 bg-white">
                <p className="mb-2 font-medium">{step.details}</p>
                <ul className="list-disc pl-5 mb-4">
                  {step.examples.map((example, i) => (
                    <li key={i} className="text-gray-700">{example}</li>
                  ))}
                </ul>
                <p className="text-sm text-blue-600">
                  Relevant EU AI Act Articles: {step.articles.join(", ")}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
        <h4 className="text-lg font-semibold mb-2 text-blue-800">Key Considerations for Healthcare & Pharma</h4>
        <ul className="list-disc pl-5 text-gray-700 space-y-2">
          <li>Ensure AI systems for diagnosis and treatment planning are classified as high-risk and comply with stringent requirements.</li>
          <li>Implement robust data governance practices to protect patient data used in AI training and operations.</li>
          <li>Establish clear protocols for human oversight in critical decision-making processes involving AI.</li>
          <li>Regularly assess and mitigate potential biases in AI models, especially those affecting patient outcomes.</li>
          <li>Maintain comprehensive documentation of AI systems, including their development, testing, and ongoing performance monitoring.</li>
        </ul>
      </div>
    </div>
  );
};

export default RiskManagement;