import React from 'react';

export const Tabs = ({ children, value, onValueChange, className, ...props }) => (
  <div className={`${className}`} {...props}>
    {children}
  </div>
);

export const TabsList = ({ children, className, ...props }) => (
  <div className={`flex space-x-2 mb-4 ${className}`} {...props}>
    {children}
  </div>
);

export const TabsTrigger = ({ children, value, active, onClick, className, ...props }) => (
  <button
    onClick={() => onClick(value)}
    className={`px-4 py-2 font-medium rounded-md transition-colors
      ${active 
        ? 'bg-blue-500 text-white' 
        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}
      ${className}`}
    {...props}
  >
    {children}
  </button>
);

export const TabsContent = ({ children, value, activeValue, className, ...props }) => (
  <div className={`${value === activeValue ? '' : 'hidden'} ${className}`} {...props}>
    {children}
  </div>
);