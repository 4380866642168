import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import LandingPage from './components/LandingPage';
import DashboardWireframe from './components/DashboardWireframe';
import AIMSDashboardWireframe from './components/AIMSDashboardWireframe';
import PrivacyPolicy from './components/PrivacyPolicy';
import GDPRNotice from './components/GDPRNotice';

function ErrorFallback({error}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{color: 'red'}}>{error.message}</pre>
    </div>
  )
}

function App() {
  const isDashboardEnabled = process.env.REACT_APP_ENABLE_DASHBOARDS === 'true';

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <LandingPage />
            </ErrorBoundary>
          } />
          <Route path="/cms-dashboard" element={
            isDashboardEnabled ? (
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <DashboardWireframe />
              </ErrorBoundary>
            ) : <Navigate replace to="/" />
          } />
          <Route path="/aims-dashboard" element={
            isDashboardEnabled ? (
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <AIMSDashboardWireframe />
              </ErrorBoundary>
            ) : <Navigate replace to="/" />
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* Redirect old dashboard URL to CMS dashboard */}
          <Route path="/dashboard" element={<Navigate replace to="/cms-dashboard" />} />
          {/* Catch-all route for any unmatched paths */}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        <GDPRNotice />
      </div>
    </Router>
  );
}

export default App;