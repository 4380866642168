import React, { useState, useEffect, useCallback } from 'react';
import {
  VStack, Heading, FormControl, FormLabel, Input, Text, 
  Button, Tooltip, useToast, Checkbox, CheckboxGroup, HStack, Spinner,
  Tag, TagLabel, TagCloseButton
} from "@chakra-ui/react";
import { HelpCircle, Brain, PlusCircle } from 'lucide-react';
import DepartmentDisplay from './DepartmentDisplay';

const placeholderObjectives = {
  'Risk Assessment': [
    "Identify and evaluate potential compliance risks across all business units",
    "Assess the likelihood and impact of identified risks",
    "Prioritize risks based on their potential impact on the organization",
    "Develop mitigation strategies for high-priority risks",
    "Establish a risk monitoring and reporting framework"
  ],
  'Compliance Monitoring': [
    "Implement continuous monitoring of key compliance indicators",
    "Develop and maintain a compliance dashboard for real-time insights",
    "Establish regular compliance audits and assessments",
    "Create a system for tracking and addressing compliance issues",
    "Implement automated alerts for potential compliance violations"
  ]
};

const Step1DefineProcess = ({ 
  processType, 
  processName, 
  setProcessName, 
  objectives, 
  setObjectives, 
  involvedDepartments, 
  setInvolvedDepartments, 
  aiSuggestions,
  fetchAISuggestions
}) => {
  const [isGeneratingObjectives, setIsGeneratingObjectives] = useState(false);
  const [suggestedObjectives, setSuggestedObjectives] = useState([]);
  const [customObjective, setCustomObjective] = useState('');
  const [isAddingCustom, setIsAddingCustom] = useState(false);
  const toast = useToast();

  const generateObjectives = useCallback(async () => {
    setIsGeneratingObjectives(true);
    try {
      // Simulating AI generation with placeholder objectives
      const simulatedAIObjectives = placeholderObjectives[processType] || [];
      setSuggestedObjectives(simulatedAIObjectives);
      setObjectives(simulatedAIObjectives.join('\n'));
    } catch (error) {
      console.error("Error generating objectives:", error);
      toast({
        title: "Error",
        description: "Failed to generate objectives. Using placeholder objectives instead.",
        status: "warning",
        duration: 3000,
      });
    } finally {
      setIsGeneratingObjectives(false);
    }
  }, [processType, setObjectives, toast]);

  useEffect(() => {
    if (processType && (!objectives || objectives.length === 0)) {
      generateObjectives();
    }
  }, [processType, objectives, generateObjectives]);

  const handleObjectiveChange = (selectedObjectives) => {
    setObjectives(selectedObjectives.join('\n'));
  };

  const addCustomObjective = () => {
    if (customObjective.trim()) {
      setSuggestedObjectives(prev => [...prev, customObjective.trim()]);
      setObjectives(prev => prev ? `${prev}\n${customObjective.trim()}` : customObjective.trim());
      setCustomObjective('');
      setIsAddingCustom(false);
    }
  };

  const removeObjective = (objectiveToRemove) => {
    setSuggestedObjectives(prev => prev.filter(obj => obj !== objectiveToRemove));
    setObjectives(prev => prev.split('\n').filter(obj => obj !== objectiveToRemove).join('\n'));
  };

  return (
    <VStack spacing={6} align="stretch">
      <Heading size="md">Step 1: Define Your {processType} Process</Heading>
      
      <FormControl>
        <FormLabel>Process Name</FormLabel>
        <Tooltip label="This process will be saved as a draft in the Lokatial CMS for future reference and editing.">
          <Input 
            placeholder="Enter a name for your process" 
            value={processName} 
            onChange={(e) => setProcessName(e.target.value)} 
          />
        </Tooltip>
      </FormControl>

      <FormControl>
        <FormLabel>
          Objectives
          <Tooltip label="Select from AI-generated objectives or add your own">
            <span><HelpCircle size={16} style={{ marginLeft: '8px', cursor: 'pointer' }} /></span>
          </Tooltip>
        </FormLabel>
        {isGeneratingObjectives ? (
          <HStack>
            <Spinner size="sm" />
            <Text>Generating AI suggestions...</Text>
          </HStack>
        ) : (
          <>
            <VStack align="start" spacing={2}>
              {suggestedObjectives.map((objective, index) => (
                <Tag key={index} size="lg" borderRadius="full" variant="solid" colorScheme="purple">
                  <TagLabel>{objective}</TagLabel>
                  <TagCloseButton onClick={() => removeObjective(objective)} />
                </Tag>
              ))}
            </VStack>
            {isAddingCustom ? (
              <HStack mt={4}>
                <Input 
                  placeholder="Type and press Enter" 
                  value={customObjective}
                  onChange={(e) => setCustomObjective(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && addCustomObjective()}
                  onBlur={() => setIsAddingCustom(false)}
                  autoFocus
                />
              </HStack>
            ) : (
              <Button
                leftIcon={<PlusCircle size={16} />}
                onClick={() => setIsAddingCustom(true)}
                size="sm"
                mt={2}
              >
                Add Custom Objective
              </Button>
            )}
          </>
        )}
      </FormControl>

      <Button 
        leftIcon={<Brain />} 
        onClick={generateObjectives} 
        isLoading={isGeneratingObjectives}
        loadingText="Generating"
      >
        Regenerate Objectives
      </Button>

      <Text fontSize="sm" color="gray.500" mt={1}>
        Tip: Select or add objectives that best fit your process goals and requirements.
      </Text>

      <DepartmentDisplay 
        involvedDepartments={involvedDepartments}
        setInvolvedDepartments={setInvolvedDepartments}
      />
    </VStack>
  );
};

export default Step1DefineProcess;