import React, { useState } from 'react';
import { CheckCircle, AlertCircle, HelpCircle, ChevronRight, Download, Loader } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ComplianceItem = ({ title, status, progress }) => {
  const getStatusIcon = () => {
    switch (status) {
      case 'compliant':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'non-compliant':
        return <AlertCircle className="w-5 h-5 text-red-500" />;
      default:
        return <HelpCircle className="w-5 h-5 text-yellow-500" />;
    }
  };

  return (
    <div className="flex items-center justify-between p-2 border-b">
      <div className="flex-grow">
        <div className="flex items-center justify-between">
          <span>{title}</span>
          {getStatusIcon()}
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
          <div 
            className="bg-blue-600 h-2.5 rounded-full" 
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const EUAIActComplianceTracker = () => {
  const navigate = useNavigate();
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [reportUrl, setReportUrl] = useState(null);

  const complianceItems = [
    { title: 'Risk Management System', status: 'compliant', progress: 100 },
    { title: 'Data Governance', status: 'non-compliant', progress: 30 },
    { title: 'Technical Documentation', status: 'in-progress', progress: 60 },
    { title: 'Record-keeping', status: 'compliant', progress: 100 },
    { title: 'Transparency', status: 'in-progress', progress: 75 },
    { title: 'Human Oversight', status: 'non-compliant', progress: 45 },
  ];

  const handleViewFullCompliance = () => {
    navigate('/eu-ai-act-compliance');
  };

  const generateReport = async () => {
    setIsGeneratingReport(true);
    setReportUrl(null);

    // Simulate API call to generate report
    await new Promise(resolve => setTimeout(resolve, 3000));

    // Simulate a generated report URL
    const simulatedReportUrl = '/api/reports/eu-ai-act-compliance-report.pdf';
    setReportUrl(simulatedReportUrl);
    setIsGeneratingReport(false);
  };

  return (
    <div className="border rounded-lg p-4">
      <h2 className="text-xl font-semibold mb-4">EU AI Act Compliance Tracker</h2>
      {complianceItems.map((item, index) => (
        <ComplianceItem key={index} title={item.title} status={item.status} progress={item.progress} />
      ))}
      <div className="mt-4 flex justify-between items-center">
        <button 
          onClick={handleViewFullCompliance}
          className="text-blue-600 hover:text-blue-800 flex items-center"
        >
          View Full Compliance Hub
          <ChevronRight className="ml-1" size={20} />
        </button>
        {!reportUrl && (
          <button 
            onClick={generateReport} 
            disabled={isGeneratingReport}
            className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center ${isGeneratingReport ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isGeneratingReport ? (
              <>
                <Loader className="animate-spin mr-2" size={20} />
                Generating Report...
              </>
            ) : (
              'Generate Compliance Report'
            )}
          </button>
        )}
        {reportUrl && (
          <a 
            href={reportUrl} 
            download="EU_AI_Act_Compliance_Report.pdf"
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center"
          >
            <Download className="mr-2" size={20} />
            Download Report
          </a>
        )}
      </div>
    </div>
  );
};

export default EUAIActComplianceTracker;