import React from 'react';
import AIAnalyzeButton from './AIAnalyzeButton';
import { Upload, FileText } from 'lucide-react';

const PolicyList = ({ policies, onUpload, onAnalyze }) => (
  <div className="bg-white p-4 rounded shadow">
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-lg font-semibold flex items-center">
        <FileText size={20} className="mr-2" />
        Policies
      </h3>
      <button 
        onClick={() => onUpload('policy')} 
        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 flex items-center"
      >
        <Upload size={16} className="mr-2" />
        Upload Policy
      </button>
    </div>
    {policies.length > 0 ? (
      <ul className="space-y-2">
        {policies.map((policy) => (
          <li key={policy.id} className="flex justify-between items-center p-2 hover:bg-gray-50 rounded">
            <span className="font-medium">{policy.name}</span>
            <div className="flex items-center">
              <span className="text-sm text-gray-500 mr-4">Last updated: {policy.lastUpdated}</span>
              <AIAnalyzeButton onClick={onAnalyze} item={policy} />
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <p className="text-gray-500">No policies available. Upload a policy to get started.</p>
    )}
  </div>
);

export default PolicyList;