import React, { useState } from 'react';
import { Database, Cloud, Lock, FileText, Activity, Brain, BarChart , Users, Briefcase, Layers, ChevronDown, ChevronUp } from 'lucide-react';

const IntegrationCategory = ({ icon: Icon, title, integrations }) => (
  <div className="bg-white p-6 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-105">
    <div className="flex items-center justify-center h-16 w-16 rounded-full bg-blue-100 mx-auto mb-4">
      <Icon className="h-8 w-8 text-blue-600" />
    </div>
    <h3 className="text-xl font-semibold text-gray-900 mb-4 text-center">{title}</h3>
    <ul className="space-y-2">
      {integrations.map((integration, index) => (
        <li key={index} className="flex items-center">
          <div className="w-2 h-2 bg-blue-500 rounded-full mr-2"></div>
          <span className="text-gray-600">{integration}</span>
        </li>
      ))}
    </ul>
  </div>
);

const IntegrationEcosystemSection = () => {
  const [showAll, setShowAll] = useState(false);

  const integrationCategories = [
    {
      icon: Activity,
      title: "Clinical Trial Management",
      integrations: ['Medidata Rave', 'Oracle Clinical', 'IBM Clinical Development', 'Veeva Vault CTMS', 'Bioclinica']
    },
    {
      icon: Database,
      title: "Healthcare Data Management",
      integrations: ['Epic', 'Cerner', 'Allscripts', 'Meditech', 'HL7 FHIR']
    },
    {
      icon: Lock,
      title: "Security & Compliance",
      integrations: ['Okta', 'Auth0', 'OneTrust', 'MetricStream', 'RSA Archer']
    },
    {
      icon: FileText,
      title: "Document Management",
      integrations: ['Veeva Vault', 'OpenText', 'Box', 'Documentum', 'SharePoint']
    },
    {
      icon: Brain,
      title: "AI & Machine Learning",
      integrations: ['TensorFlow', 'PyTorch', 'IBM Watson', 'Google AI Platform', 'Azure Machine Learning']
    },
    {
      icon: BarChart,
      title: "Analytics & Reporting",
      integrations: ['Tableau', 'Power BI', 'Qlik', 'SAS', 'Spotfire']
    },
    {
      icon: Briefcase,
      title: "Enterprise Systems",
      integrations: ['SAP ERP', 'Oracle ERP Cloud', 'Microsoft Dynamics 365', 'Salesforce', 'Workday']
    },
    {
      icon: Layers,
      title: "Enterprise Data Platforms",
      integrations: ['Palantir Foundry', 'Alteryx', 'Informatica', 'Talend', 'Databricks']
    },
    {
      icon: Cloud,
      title: "Cloud & Infrastructure",
      integrations: ['AWS', 'Microsoft Azure', 'Google Cloud', 'IBM Cloud', 'Salesforce Health Cloud']
    },
    {
      icon: Users,
      title: "Collaboration & Communication",
      integrations: ['Microsoft Teams', 'Slack', 'Zoom', 'Jira', 'Confluence']
    }
  ];

  const visibleCategories = showAll ? integrationCategories : integrationCategories.slice(0, 6);

  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center mb-8 text-gradient leading-relaxed">
          Seamless Integration Ecosystem
        </h2>
        <p className="text-xl text-gray-600 text-center mb-12 max-w-3xl mx-auto">
          LOKATIAL integrates with your existing tech stack, ensuring a smooth implementation and maximizing the value of your current investments in healthcare and pharma IT infrastructure.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          {visibleCategories.map((category, index) => (
            <IntegrationCategory
              key={index}
              icon={category.icon}
              title={category.title}
              integrations={category.integrations}
            />
          ))}
        </div>
        {integrationCategories.length > 6 && (
          <div className="text-center">
            <button
              className="bg-blue-600 text-white px-6 py-2 rounded-full inline-flex items-center justify-center transition duration-300 hover:bg-blue-700"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? (
                <>
                  <ChevronUp className="mr-2" size={20} />
                  Show Less
                </>
              ) : (
                <>
                  <ChevronDown className="mr-2" size={20} />
                  Show More
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default IntegrationEcosystemSection;