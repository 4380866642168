import React from 'react';
import { AlertTriangle, CheckCircle, Clock } from 'lucide-react';

const AIIncidentManagement = ({ incidents }) => {
  return (
    <div className="bg-white p-4 rounded shadow">
      <h2 className="text-lg font-semibold mb-4">AI Incident Management</h2>
      <ul className="space-y-2">
        {incidents.map((incident) => (
          <li key={incident.id} className="flex items-start p-2 hover:bg-gray-100 rounded">
            <div className={`mr-3 mt-1 ${
              incident.status === 'Resolved' ? 'text-green-500' :
              incident.status === 'In Progress' ? 'text-yellow-500' :
              'text-red-500'
            }`}>
              {incident.status === 'Resolved' ? <CheckCircle size={20} /> :
               incident.status === 'In Progress' ? <Clock size={20} /> :
               <AlertTriangle size={20} />}
            </div>
            <div>
              <p className="font-medium">{incident.description}</p>
              <p className="text-sm text-gray-500">Date: {incident.date}</p>
              <p className={`text-sm ${
                incident.status === 'Resolved' ? 'text-green-600' :
                incident.status === 'In Progress' ? 'text-yellow-600' :
                'text-red-600'
              }`}>
                Status: {incident.status}
              </p>
            </div>
          </li>
        ))}
      </ul>
      <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 w-full">
        View All Incidents
      </button>
    </div>
  );
};

export default AIIncidentManagement;