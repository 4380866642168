import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box, Heading, Text, Table, Thead, Tbody, Tr, Th, Td,
  Input, Select, Button, VStack, HStack, Textarea, UnorderedList,
  FormControl, FormLabel, Checkbox, useToast, Alert, AlertIcon, Badge, Tooltip, useColorModeValue, NumberInput, NumberInputField, Collapse, Icon, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
  List, ListItem, ListIcon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { FaInfoCircle, FaComment, FaChartBar, FaExclamationTriangle, FaCheckCircle, FaClipboardList, FaHistory, FaBalanceScale, FaTools } from 'react-icons/fa';
import { RiAiGenerate } from "react-icons/ri";
import { debounce } from 'lodash';

const logError = (context, error) => {
  console.error(`Error in ${context}:`, error);
};

const CommentModal = ({ isOpen, onClose, section, onSave, existingComment }) => {
  const [comment, setComment] = useState(existingComment || '');

  const handleSave = () => {
    onSave(section, comment);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Comment to {section}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Enter your comment here..."
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save Comment
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const RiskPrioritization = ({ selectedRiskCategories, involvedDepartments, aiSuggestions, riskData, onDataChange }) => {
  const [localRisks, setLocalRisks] = useState({});

  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const aiBadgeColor = useColorModeValue("blue.50", "blue.900");

  useEffect(() => {
    const initialRisks = selectedRiskCategories.reduce((acc, category) => {
      acc[category] = riskData[category] || {
        description: getAIGeneratedDescription(category),
        likelihood: 1,
        impact: 1,
        score: 1,
        responsibleDepartment: '',
        isAIGenerated: true
      };
      return acc;
    }, {});
    setLocalRisks(initialRisks);
  }, [selectedRiskCategories, riskData, aiSuggestions]);

  const getAIGeneratedDescription = (category) => {
    const descriptions = {
      "Anti-Bribery & Corruption (ABAC)": "Risks associated with bribery and corruption in business operations, including potential legal violations, reputational damage, and financial losses. This encompasses risks of employees offering or accepting bribes, facilitation payments, and conflicts of interest.",
      "Third-Party Risk Management (TPRM)": "Potential risks arising from relationships with external parties such as suppliers, vendors, and contractors. This includes risks of data breaches, non-compliance with regulations, operational disruptions, and reputational damage due to third-party actions or failures.",
      "Ethics & Compliance": "Risks related to violations of ethical standards and compliance regulations within the organization. This encompasses risks of employee misconduct, regulatory non-compliance, inadequate internal controls, and failure to foster an ethical corporate culture.",
      "HCP Payments & Transfers of Value": "Risks associated with financial interactions between healthcare professionals (HCPs) and the organization. This includes risks of non-compliance with transparency regulations, improper influence on HCPs, and potential legal and reputational consequences.",
      "HCP Contracting": "Risks related to the process of engaging and contracting healthcare professionals. This encompasses risks of non-compliant contracts, improper compensation arrangements, and potential violations of anti-kickback laws.",
      "HCP Transparency Reporting": "Risks associated with the accurate and timely reporting of payments and transfers of value to healthcare professionals. This includes risks of data inaccuracies, missed reporting deadlines, and non-compliance with transparency laws and regulations."
    };
    return descriptions[category] || "Potential risks associated with this category that could impact organizational objectives and compliance.";
  };

  const debouncedOnDataChange = useMemo(
    () => debounce(onDataChange, 300),
    [onDataChange]
  );

  const updateRisk = useCallback((category, field, value) => {
    setLocalRisks(prevRisks => {
      const updatedRisks = {
        ...prevRisks,
        [category]: {
          ...prevRisks[category],
          [field]: value,
          isAIGenerated: field === 'description' ? false : prevRisks[category].isAIGenerated
        }
      };
      if (field === 'likelihood' || field === 'impact') {
        updatedRisks[category].score = updatedRisks[category].likelihood * updatedRisks[category].impact;
      }
      debouncedOnDataChange(updatedRisks);
      return updatedRisks;
    });
  }, [debouncedOnDataChange]);

  const handleNumberInputChange = useCallback((category, field, value) => {
    const numValue = parseInt(value);
    if (!isNaN(numValue) && numValue >= 1 && numValue <= 5) {
      updateRisk(category, field, numValue);
    }
  }, [updateRisk]);

  return (
    <Box bg={bgColor} p={4} borderRadius="lg" boxShadow="sm" borderColor={borderColor} borderWidth={1}>
      <Text fontSize="lg" fontWeight="bold" mb={4}>Risk Prioritization Matrix</Text>
      <Text fontSize="sm" mb={4}>Assess the likelihood and impact of each risk category to prioritize your focus areas.</Text>
      
      <Accordion allowMultiple>
        {Object.entries(localRisks).map(([category, risk]) => (
          <AccordionItem key={category} border="1px" borderColor={borderColor} borderRadius="md" mb={2}>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontSize="sm" fontWeight="bold">
                {category}
              </Box>
              <Badge colorScheme={risk.score > 15 ? "red" : risk.score > 10 ? "yellow" : "green"} mr={2}>
                {risk.score}
              </Badge>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <VStack align="stretch" spacing={3}>
                <Box>
                  <Text fontSize="xs" mb={1}>Description</Text>
                  <Textarea
                    value={risk.description}
                    onChange={(e) => updateRisk(category, 'description', e.target.value)}
                    size="sm"
                    rows={4}
                  />
                  {risk.isAIGenerated && (
                    <Tooltip label="AI-generated content. Feel free to edit and expand.">
                      <Badge colorScheme="blue" variant="subtle" bg={aiBadgeColor} mt={1}>
                        <Icon as={RiAiGenerate} mr={1} />
                        AI Generated
                      </Badge>
                    </Tooltip>
                  )}
                </Box>
                <HStack justify="space-between">
                  <Box>
                    <Text fontSize="xs" mb={1}>Likelihood (1-5)</Text>
                    <NumberInput
                      value={risk.likelihood}
                      onChange={(_, valueNumber) => handleNumberInputChange(category, 'likelihood', valueNumber)}
                      min={1}
                      max={5}
                      size="sm"
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Box>
                  <Box>
                    <Text fontSize="xs" mb={1}>Impact (1-5)</Text>
                    <NumberInput
                      value={risk.impact}
                      onChange={(_, valueNumber) => handleNumberInputChange(category, 'impact', valueNumber)}
                      min={1}
                      max={5}
                      size="sm"
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Box>
                </HStack>
                <Box>
                  <Text fontSize="xs" mb={1}>Responsible Department</Text>
                  <Select
                    value={risk.responsibleDepartment}
                    onChange={(e) => updateRisk(category, 'responsibleDepartment', e.target.value)}
                    size="sm"
                  >
                    <option value="">Select Department</option>
                    {involvedDepartments.map(dept => (
                      <option key={dept} value={dept}>{dept}</option>
                    ))}
                  </Select>
                </Box>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export const RiskAnalysis = ({ selectedRiskCategories, involvedDepartments, aiSuggestions, riskPrioritizationData = {} }) => {
  const [selectedRisk, setSelectedRisk] = useState('');
  const [riskAnalysis, setRiskAnalysis] = useState({});
  const [comments, setComments] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentSection, setCurrentSection] = useState('');

  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const fetchRiskAnalysis = useCallback(async (risk) => {
    // Simulated API call - replace with actual API in production
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Tailored content based on risk category
    const riskSpecificData = {
      "HCP Contracting": {
        factors: [
          "Inconsistent contract terms across regions",
          "Lack of centralized contract management system",
          "Inadequate fair market value (FMV) documentation",
          "Non-compliance with anti-kickback regulations"
        ],
        impactAreas: [
          "Legal and regulatory penalties",
          "Reputational damage among healthcare professionals",
          "Financial losses due to improper payments",
          "Operational inefficiencies in HCP engagement"
        ],
        mitigationStrategies: [
          "Implement a centralized contract management system",
          "Establish standardized contract templates with legal approval",
          "Develop a robust FMV determination and documentation process",
          "Conduct regular audits of HCP contracts and payments"
        ],
        keyIndicators: [
          "Percentage of contracts using approved templates",
          "Average time to contract approval",
          "Number of FMV exceptions identified",
          "Audit findings related to HCP contracts"
        ],
        stakeholders: [
          "Legal Department",
          "Compliance Officer",
          "Medical Affairs",
          "Finance Department"
        ]
      },
      // Add other risk categories here...
    };

    return {
      ...riskSpecificData[risk],
      relevantStandards: [
        "ISO 37001:2016 - Anti-bribery Management Systems",
        "PhRMA Code on Interactions with Health Care Professionals",
        "EFPIA Code of Practice"
      ],
      historicalData: {
        previousIncidents: 2,
        averageImpact: "Moderate",
        trendDirection: "Stable"
      }
    };
  }, []);

  useEffect(() => {
    if (selectedRisk) {
      fetchRiskAnalysis(selectedRisk).then(setRiskAnalysis);
    }
  }, [selectedRisk, fetchRiskAnalysis]);

  const handleCommentClick = (section) => {
    setCurrentSection(section);
    onOpen();
  };

  const saveComment = (section, comment) => {
    setComments(prevComments => ({
      ...prevComments,
      [selectedRisk]: {
        ...(prevComments[selectedRisk] || {}),
        [section]: comment
      }
    }));
  };

  const renderSection = (title, icon, content) => (
    <AccordionItem>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <HStack>
            {React.createElement(icon, { color: "blue.500", size: "1em" })}
            <Text fontWeight="bold">{title}</Text>
            {comments[selectedRisk]?.[title] && (
              <Tooltip label="View comment">
                <Badge colorScheme="green">1</Badge>
              </Tooltip>
            )}
          </HStack>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        {content}
        <Button
          leftIcon={<FaComment />}
          size="sm"
          mt={2}
          onClick={() => handleCommentClick(title)}
        >
          {comments[selectedRisk]?.[title] ? "Edit Comment" : "Add Comment"}
        </Button>
      </AccordionPanel>
    </AccordionItem>
  );

  const getRiskScore = (risk) => {
    if (riskPrioritizationData[risk]) {
      return riskPrioritizationData[risk].score || 'N/A';
    }
    return 'N/A';
  };

  const renderList = (items, icon) => (
    <UnorderedList spacing={1} styleType="none" ml={0}>
      {items?.map((item, index) => (
        <ListItem key={index} display="flex" alignItems="center">
          {React.createElement(icon, { color: "green.500", size: "1em", style: { marginRight: "8px" } })}
          <Text>{item}</Text>
        </ListItem>
      ))}
    </UnorderedList>
  );

  return (
    <Box bg={bgColor} p={4} borderRadius="md" borderColor={borderColor} borderWidth={1}>
      <VStack align="stretch" spacing={4}>
        <HStack justifyContent="space-between">
          <Text fontSize="md" fontWeight="bold">Select Risk Category for Analysis:</Text>
          <Select
            value={selectedRisk}
            onChange={(e) => setSelectedRisk(e.target.value)}
            placeholder="Choose a risk"
            width="60%"
            size="sm"
          >
            {selectedRiskCategories.map(risk => (
              <option key={risk} value={risk}>{risk}</option>
            ))}
          </Select>
        </HStack>

        {selectedRisk && (
          <>
            <HStack justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="bold">{selectedRisk} Analysis</Text>
              <HStack>
                <Badge colorScheme="purple">Likelihood: {riskPrioritizationData[selectedRisk]?.likelihood || 'N/A'}/5</Badge>
                <Badge colorScheme="purple">Impact: {riskPrioritizationData[selectedRisk]?.impact || 'N/A'}/5</Badge>
                <Tooltip label="Risk score from prioritization">
                  <Badge colorScheme="red">
                    Risk Score: {getRiskScore(selectedRisk)}
                  </Badge>
                </Tooltip>
              </HStack>
            </HStack>

            <Accordion allowMultiple>
              {renderSection("Risk Factors and Impact Areas", FaExclamationTriangle, 
                <HStack alignItems="flex-start" spacing={4}>
                  <VStack align="stretch" flex={1}>
                    <Text fontWeight="bold">Risk Factors</Text>
                    {renderList(riskAnalysis.factors, FaClipboardList)}
                  </VStack>
                  <VStack align="stretch" flex={1}>
                    <Text fontWeight="bold">Impact Areas</Text>
                    {renderList(riskAnalysis.impactAreas, FaBalanceScale)}
                  </VStack>
                </HStack>
              )}

              {renderSection("Mitigation Strategies", FaCheckCircle,
                renderList(riskAnalysis.mitigationStrategies, FaCheckCircle)
              )}

              {renderSection("Relevant Standards and Frameworks", FaClipboardList,
                renderList(riskAnalysis.relevantStandards, FaCheckCircle)
              )}

              {renderSection("Key Risk Indicators", FaChartBar,
                renderList(riskAnalysis.keyIndicators, FaChartBar)
              )}

              {renderSection("Historical Data", FaHistory,
                <VStack align="start">
                  <Text>Previous Incidents: {riskAnalysis.historicalData?.previousIncidents}</Text>
                  <Text>Average Impact: {riskAnalysis.historicalData?.averageImpact}</Text>
                  <Text>Trend: {riskAnalysis.historicalData?.trendDirection}</Text>
                </VStack>
              )}

              {renderSection("Key Stakeholders", FaClipboardList,
                renderList(riskAnalysis.stakeholders, FaClipboardList)
              )}
            </Accordion>
          </>
        )}
      </VStack>

      <CommentModal
        isOpen={isOpen}
        onClose={onClose}
        section={currentSection}
        onSave={saveComment}
        existingComment={comments[selectedRisk]?.[currentSection]}
      />
    </Box>
  );
};

export const ControlDesign = ({ selectedRiskCategories, involvedDepartments, aiSuggestions }) => {
  const [controls, setControls] = useState([]);
  const [newControl, setNewControl] = useState({ name: '', type: '', category: '', implemented: false });
  const [editingControl, setEditingControl] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    if (aiSuggestions?.controlSuggestions) {
      const suggestedControls = selectedRiskCategories.flatMap(category => 
        (aiSuggestions.controlSuggestions[category] || []).map(control => ({
          name: control,
          type: 'Suggested',
          implemented: false,
          category
        }))
      );
      
      setControls(prevControls => {
        const existingControlNames = new Set(prevControls.map(c => c.name));
        const newControls = suggestedControls.filter(c => !existingControlNames.has(c.name));
        return [...prevControls, ...newControls];
      });
    }
  }, [aiSuggestions, selectedRiskCategories]);

  const addControl = () => {
    if (newControl.name && newControl.type && newControl.category) {
      setControls(prevControls => [...prevControls, { ...newControl, implemented: false }]);
      setNewControl({ name: '', type: '', category: '', implemented: false });
      toast({
        title: "Control added",
        description: "New control has been added successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const toggleControl = (index) => {
    setControls(prevControls => prevControls.map((control, i) => 
      i === index ? { ...control, implemented: !control.implemented } : control
    ));
  };

  const openEditModal = (control) => {
    setEditingControl(control);
    onOpen();
  };

  const saveEditedControl = () => {
    setControls(prevControls => prevControls.map(control => 
      control.name === editingControl.name ? editingControl : control
    ));
    onClose();
    toast({
      title: "Control updated",
      description: "The control has been updated successfully.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box p={4} borderRadius="lg" boxShadow="sm" borderWidth={1}>
      <Heading size="md" mb={2}>Control Design</Heading>
      <Text fontSize="sm" mb={4}>Design and implement controls to mitigate identified risks.</Text>

      <Table variant="simple" size="sm" mb={4}>
        <Thead>
          <Tr>
            <Th>Control Name</Th>
            <Th>Type</Th>
            <Th>Risk Category</Th>
            <Th>Implemented</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {controls.map((control, index) => (
            <Tr key={index}>
              <Td>{control.name}</Td>
              <Td>{control.type}</Td>
              <Td>{control.category}</Td>
              <Td>
                <Checkbox 
                  isChecked={control.implemented}
                  onChange={() => toggleControl(index)}
                  size="sm"
                />
              </Td>
              <Td>
                <Button size="sm" leftIcon={<EditIcon />} onClick={() => openEditModal(control)}>
                  Edit
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <VStack align="stretch" spacing={3}>
        <Heading size="sm" mb={2}>Add New Control</Heading>
        <HStack>
          <Input 
            placeholder="New control name" 
            value={newControl.name}
            onChange={(e) => setNewControl({...newControl, name: e.target.value})}
            size="sm"
          />
          <Select
            placeholder="Control type"
            value={newControl.type}
            onChange={(e) => setNewControl({...newControl, type: e.target.value})}
            size="sm"
          >
            <option value="Preventive">Preventive</option>
            <option value="Detective">Detective</option>
            <option value="Corrective">Corrective</option>
          </Select>
          <Select
            placeholder="Risk category"
            value={newControl.category}
            onChange={(e) => setNewControl({...newControl, category: e.target.value})}
            size="sm"
          >
            {selectedRiskCategories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </Select>
          <Button 
            onClick={addControl} 
            
            colorScheme="purple" 
            size="sm"
          >
            +
          </Button>
        </HStack>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Control</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Input 
                placeholder="Control name" 
                value={editingControl?.name || ''}
                onChange={(e) => setEditingControl({...editingControl, name: e.target.value})}
              />
              <Select
                placeholder="Control type"
                value={editingControl?.type || ''}
                onChange={(e) => setEditingControl({...editingControl, type: e.target.value})}
              >
                <option value="Preventive">Preventive</option>
                <option value="Detective">Detective</option>
                <option value="Corrective">Corrective</option>
              </Select>
              <Select
                placeholder="Risk category"
                value={editingControl?.category || ''}
                onChange={(e) => setEditingControl({...editingControl, category: e.target.value})}
              >
                {selectedRiskCategories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </Select>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={saveEditedControl}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export const RiskTreatment = ({ selectedRiskCategories, involvedDepartments, aiSuggestions }) => {
  const toast = useToast();
  const [selectedRisk, setSelectedRisk] = useState('');
  const [treatmentOption, setTreatmentOption] = useState('');
  const [treatmentPlan, setTreatmentPlan] = useState('');

  useEffect(() => {
    if (selectedRisk && aiSuggestions?.treatmentStrategies) {
      setTreatmentPlan(aiSuggestions.treatmentStrategies[selectedRisk] || '');
    } else {
      setTreatmentPlan('');
    }
  }, [selectedRisk, aiSuggestions]);

  const handleSubmit = () => {
    toast({
      title: "Risk Treatment Plan Submitted",
      description: "Your risk treatment plan has been recorded.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box>
      <Heading size="md" mb={4}>Risk Treatment</Heading>
      <Text mb={4}>Develop and implement plans to treat identified risks.</Text>
      <FormControl mb={4}>
        <FormLabel>Select Risk to Treat</FormLabel>
        <Select 
          value={selectedRisk}
          onChange={(e) => setSelectedRisk(e.target.value)}
          placeholder="Select a risk"
        >
          {selectedRiskCategories.map(risk => (
            <option key={risk} value={risk}>{risk}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl mb={4}>
        <FormLabel>Treatment Option</FormLabel>
        <Select 
          value={treatmentOption}
          onChange={(e) => setTreatmentOption(e.target.value)}
          placeholder="Select treatment option"
        >
          <option value="mitigate">Mitigate</option>
          <option value="transfer">Transfer</option>
          <option value="avoid">Avoid</option>
          <option value="accept">Accept</option>
        </Select>
      </FormControl>
      <FormControl mb={4}>
        <FormLabel>Treatment Plan</FormLabel>
        <Textarea 
          value={treatmentPlan}
          onChange={(e) => setTreatmentPlan(e.target.value)}
          placeholder="Describe the treatment plan in detail, including actions, resources, timeline, and expected outcomes."
        />
      </FormControl>
      <Button colorScheme="blue" onClick={handleSubmit}>Submit Treatment Plan</Button>
      {aiSuggestions?.riskTreatmentTips && (
        <Alert status="info" mt={4}>
          <AlertIcon />
          {aiSuggestions.riskTreatmentTips}
        </Alert>
      )}
    </Box>
  );
};